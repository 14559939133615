import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";

import * as actions from "../../store/actions/index";
import _ from "lodash";

import classes from "./Media.module.sass";

import placeholderImage from "shared/icons/placeholder.svg";
import playIcon from "shared/icons/play.svg";
import iconMediaImageLogo from "shared/icons/media_image_logo.svg";
import iconMediaVideoLogo from "shared/icons/media_video_logo.svg";
import iconMediaGradientCircle from "shared/icons/media_gradient_circle.svg";
import iconMediaBackward from "shared/icons/media_backward.svg";
import iconMediaForward from "shared/icons/media_forward.svg";
import iconMediaPause from "shared/icons/media_pause.svg";
import iconMediaX from "shared/icons/media_x.svg";

function Media({ media, currentMedia, sendMQTTCurrentMedia, sendMQTTScrubMedia }) {
  const [mediaModalOpen, changeMediaModalOpen] = useState(false);

  const startMedia = () => {
    if (!currentMedia || currentMedia.airtableID !== media.id) {
      sendMQTTCurrentMedia(media);
      changeMediaModalOpen(false);
    }
  };

  const stopMedia = () => {
    sendMQTTCurrentMedia({});
  };

  let fields = media.fields;
  let controls = (
    <div className={classes.PlayButton} onClick={startMedia}>
      <img alt={"Play Icon"} src={playIcon} />
    </div>
  );
  let playingClass = null;
  if (currentMedia && currentMedia.id === media.id) {
    playingClass = classes.Playing;
    const isVideo = _.get(fields, "type", "Video") === "Video";
    controls = (
      <>
        {isVideo && (
          <div className={classes.BackwardButton} onClick={() => sendMQTTScrubMedia(-15)}>
            <img className={classes.BorderCircle} alt={"Circle around button"} src={iconMediaGradientCircle} />
            <img alt={"Backward Icon"} src={iconMediaBackward} />
          </div>
        )}
        <div className={classes.PauseButton} onClick={stopMedia}>
          <img className={classes.BorderCircle} alt={"Circle around button"} src={iconMediaGradientCircle} />
          <img alt={"Pause Icon"} src={iconMediaPause} />
        </div>
        {isVideo && (
          <div className={classes.ForwardButton} onClick={() => sendMQTTScrubMedia(15)}>
            <img className={classes.BorderCircle} alt={"Circle around button"} src={iconMediaGradientCircle} />
            <img alt={"Forward Icon"} src={iconMediaForward} />
          </div>
        )}
      </>
    );
  }

  const mediaInfoOpenClass = mediaModalOpen ? classes.MediaInfoOpen : classes.MediaInfoClosed;
  const mediaType = _.get(fields, "type", "Photo");
  const isImage = mediaType === "Photo" || mediaType === "Stats";

  return (
    <div className={classes.MediaWrapper}>
      <div className={classes.Media + " " + playingClass}>
        <div className={classes.HiddenContent}>
          <div className={classes.ButtonClose} onClick={() => changeMediaModalOpen(false)}>
            <img src={iconMediaX} alt={"Close media info"} />
          </div>
          <div className={classes.Description}>{_.get(fields, "description", "")}</div>
        </div>
        <div className={classes.MediaImage + " " + mediaInfoOpenClass}>
          <div className={classes.ButtonInfo} onClick={() => changeMediaModalOpen(true)}>
            <div>i</div>
          </div>
          <img
            className={classes.Thumbnail}
            src={_.get(fields, "thumbnail[0].url", placeholderImage)}
            alt={"Media preview"}
          />
          {controls}
        </div>
        <div className={classes.MediaInfos + " " + mediaInfoOpenClass}>
          <div className={classes.MediaLogo}>
            <img
              className={isImage ? classes.IconMediaImage : ""}
              src={isImage ? iconMediaImageLogo : iconMediaVideoLogo}
              alt="Logo Media"
            />
          </div>
          <div className={classes.MediaTitle}>{_.get(fields, "name", "")}</div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentMedia: state.mqttReducer.currentMedia,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getItemByID: (model, id) => {
      return dispatch(actions.getItemByID(model, id));
    },
    sendMQTTCurrentMedia: (media) => {
      return dispatch(actions.sendMQTTCurrentMedia(media));
    },
    sendMQTTScrubMedia: (scrubValue) => {
      return dispatch(actions.sendMQTTScrubMedia(scrubValue));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Media);
