
export const FETCH_DEMOS_REQUEST = 'FETCH_DEMOS_REQUEST'
export const FETCH_DEMOS_SUCCESS = 'FETCH_DEMOS_SUCCESS'
export const FETCH_DEMOS_FAILED = 'FETCH_DEMOS_FAILED'

export const FETCH_SESSIONS_REQUEST = 'FETCH_SESSIONS_REQUEST'
export const FETCH_SESSIONS_SUCCESS = 'FETCH_SESSIONS_SUCCESS'
export const FETCH_SESSIONS_FAILED = 'FETCH_SESSIONS_FAILED'

export const FETCH_PROTOS_REQUEST = 'FETCH_PROTOS_REQUEST'
export const FETCH_PROTOS_SUCCESS = 'FETCH_PROTOS_SUCCESS'
export const FETCH_PROTOS_FAILED = 'FETCH_PROTOS_FAILED'

export const FETCH_PLAYLISTS_REQUEST = 'FETCH_PLAYLISTS_REQUEST'
export const FETCH_PLAYLISTS_SUCCESS = 'FETCH_PLAYLISTS_SUCCESS'
export const FETCH_PLAYLISTS_FAILED = 'FETCH_PLAYLISTS_FAILED'

export const FETCH_PLAYLIST_ITEMS_REQUEST = 'FETCH_PLAYLIST_ITEMS_REQUEST'
export const FETCH_PLAYLIST_ITEMS_SUCCESS = 'FETCH_PLAYLIST_ITEMS_SUCCESS'
export const FETCH_PLAYLIST_ITEMS_FAILED = 'FETCH_PLAYLIST_ITEMS_FAILED'

export const FETCH_MEDIAS_REQUEST = 'FETCH_MEDIAS_REQUEST'
export const FETCH_MEDIAS_SUCCESS = 'FETCH_MEDIAS_SUCCESS'
export const FETCH_MEDIAS_FAILED = 'FETCH_MEDIAS_FAILED'

export const FETCH_VISITORS_REQUEST = 'FETCH_VISITORS_REQUEST'
export const FETCH_VISITORS_SUCCESS = 'FETCH_VISITORS_SUCCESS'
export const FETCH_VISITORS_FAILED = 'FETCH_VISITORS_FAILED'

export const FETCH_DEMONSTRATORS_REQUEST = 'FETCH_DEMONSTRATORS_REQUEST'
export const FETCH_DEMONSTRATORS_SUCCESS = 'FETCH_DEMONSTRATORS_SUCCESS'
export const FETCH_DEMONSTRATORS_FAILED = 'FETCH_DEMONSTRATORS_FAILED'

export const FETCH_RESPONSIBLES_REQUEST = 'FETCH_RESPONSIBLES_REQUEST'
export const FETCH_RESPONSIBLES_SUCCESS = 'FETCH_RESPONSIBLES_SUCCESS'
export const FETCH_RESPONSIBLES_FAILED = 'FETCH_RESPONSIBLES_FAILED'

export const FETCH_FEEDBACKS_REQUEST = 'FETCH_FEEDBACKS_REQUEST'
export const FETCH_FEEDBACKS_SUCCESS = 'FETCH_FEEDBACKS_SUCCESS'
export const FETCH_FEEDBACKS_FAILED = 'FETCH_FEEDBACKS_FAILED'

export const FETCH_TECHNOLOGIES_REQUEST = 'FETCH_TECHNOLOGIES_REQUEST'
export const FETCH_TECHNOLOGIES_SUCCESS = 'FETCH_TECHNOLOGIES_SUCCESS'
export const FETCH_TECHNOLOGIES_FAILED = 'FETCH_TECHNOLOGIES_FAILED'

export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST'
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS'
export const FETCH_TAGS_FAILED = 'FETCH_TAGS_FAILED'

export const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST'
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS'
export const FETCH_PROJECTS_FAILED = 'FETCH_PROJECTS_FAILED'

/* MQTT CLIENT INITIATION AND EVENTS */
export const CHANGE_MQTT_CLIENT_STATE = 'CHANGE_MQTT_CLIENT_STATE'
export const CREATE_MQTT_CLIENT = 'CREATE_MQTT_CLIENT'
export const ERROR_MQTT_CLIENT = 'ERROR_MQTT_CLIENT'
export const HANDLE_MQTT_MESSAGE = 'HANDLE_MQTT_MESSAGE'

/* HANDLE SPECIFIC MQTT MESSAGES */
export const MQTT_CURRENT_GUESTS_CHECKED_IN_IDS = 'MQTT_CURRENT_GUESTS_CHECKED_IN_IDS'
export const MQTT_CURRENT_PROTO_PRESETS = 'MQTT_CURRENT_PROTO_PRESETS'
export const MQTT_DISPLAY_SENSORS_INFOS = 'MQTT_DISPLAY_SENSORS_INFOS'
export const MQTT_UPDATE = 'MQTT_UPDATE'
export const MQTT_IS_TRANSITION = 'MQTT_IS_TRANSITION'
export const MQTT_STATS = 'MQTT_STATS'
export const MQTT_VOLUME = 'MQTT_VOLUME'
export const MQTT_CURRENT_DEMO = 'MQTT_CURRENT_DEMO'
export const MQTT_DEMO_STATE = 'MQTT_DEMO_STATE'
export const MQTT_CURRENT_PROTO = 'MQTT_CURRENT_PROTO'
export const MQTT_CURRENT_PROJECT = 'MQTT_CURRENT_PROJECT'
export const MQTT_CURRENT_NAVIGATION = 'MQTT_CURRENT_NAVIGATION'
export const MQTT_CURRENT_PRESET = 'MQTT_CURRENT_PRESET'
export const MQTT_CURRENT_GUESTS = 'MQTT_CURRENT_GUESTS'
export const MQTT_CURRENT_MEDIA = 'MQTT_CURRENT_MEDIA'
export const MQTT_REFRESH_CACHE = 'MQTT_REFRESH_CACHE'
export const MQTT_SCRUB_MEDIA = 'MQTT_SCRUB_MEDIA'

/* CHANGE APPLICATION STATE */
export const CHANGE_NAVIGATION_PAGE = 'CHANGE_NAVIGATION_PAGE'

