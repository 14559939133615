import * as actionTypes from '../actions/actionTypes.js'
import { updateObject } from '../utility'

const initialState = {
  demoID: "",
  playlistID: "",
  protoID: "",
  projectID: ""
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.CHANGE_NAVIGATION_PAGE:
      return updateObject(state, {
        demoID: action.demoID,
        protoID: action.protoID,
        projectID: action.projectID,
        playlistID: action.playlistID,
        playlistItemID: action.playlistItemID,
      })
    default:
      return updateObject(state)
  }
}

export default reducer
