import React, { useState } from 'react'
import * as actions from '../../store/actions/index'
// import { Modal } from 'antd';
import _ from 'lodash'
import { connect, useDispatch } from 'react-redux'
// import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons'

import iconSearch from 'shared/icons/search.svg'

import classes from './Medias.module.sass'
import Media from '../../components/Media/Media'

const Medias = ({medias, getItemByID, searchbarActivated}) => {
  const [search, changeSearch] = useState('')


  let mediaBlocks = null
  if(medias) {
    if(search != '') {
      medias = medias.filter(media => _.get(media.fields, 'name', '').toLowerCase().includes(search))
    }
    mediaBlocks = medias
      .filter((media) => media.fields.type === "Video" || media.fields.type === "Photo")
      .map((media, i) => <Media key={`media-${i}`} media={media} />);
  }

  let searchbar = null
  if(searchbarActivated) {
    searchbar = (
      <div className={classes.Searchbar}>
        <img src={iconSearch} alt="Searchbar icon" />
        <input type="text" placeholder="Search" value={search} onChange={(e) => changeSearch(e.target.value.toLowerCase())} />
      </div>
    )
  }

  return (
    <div>
      {searchbar}
      {/* {mediaModal} */}
      <div className={classes.PageTitle}>Medias</div>
      <div className={classes.Medias}>
        {mediaBlocks}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    medias: state.airtableReducer.medias
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getItemByID: (model, id) => {
      return dispatch(actions.getItemByID(model, id))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Medias)
