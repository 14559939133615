import * as actionTypes from './actionTypes'

function changeNavigationPageRequest({ demoID, protoID, projectID, playlistID, playlistItemID }) {
  return {
    type: actionTypes.CHANGE_NAVIGATION_PAGE,
    receivedAt: Date.now(),
    demoID,
    playlistID,
    playlistItemID,
    protoID,
    projectID,
  };
}

export function changeNavigationPage(ids) {
  return function(dispatch) {
    const { demoID, protoID, projectID, playlistID, playlistItemID } = ids
    dispatch(changeNavigationPageRequest({ demoID, playlistID, protoID, projectID, playlistID, playlistItemID }));
  }
}
