import React, { useState } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { navigateToPage } from '../../utils/utils'

import iconSearch from 'shared/icons/search.svg'
import iconEnterBlockArrow from 'shared/icons/enter_block_arrow.svg'

import classes from './Playlists.module.sass'

const Playlists = ({ playlists, playlistItems, searchbarActivated, isHomepage }) => {
  const [search, changeSearch] = useState('')

  let playlistBlocks = null
  if(playlists) {
    if(search != '') {
      playlists = playlists.filter(playlist => _.get(playlist.fields, 'name', '').toLowerCase().includes(search))
    }
    if(isHomepage) {
      playlists = playlists.filter(playlist => playlist.fields.showcaseHomepage).slice(0, 6)
    }
    playlistBlocks = playlists.sort((a,b)  => _.get(a, 'fields.name', '-').charCodeAt(0) - _.get(b, 'fields.name', '-').charCodeAt(0)).map((playlist, playlistIndex) => {
      let fields = playlist.fields
      const playlistItemsInPlaylist =
        playlist.fields.playlistItems &&
        playlist.fields.playlistItems
          .map((playlistItemID) => playlistItems.find((playlistItem) => playlistItem.id === playlistItemID))
          .filter((playlistItem) => typeof playlistItem !== "undefined");
      let thumbnails =
        playlistItemsInPlaylist &&
        playlistItemsInPlaylist.slice(0, 4).map((playlistItem, playlistItemIndex) => {
          let thumbnailField = playlistItem.fields.protoThumbnail || playlistItem.fields.projectThumbnail;
          return (
            <div key={`${playlist}-${playlistIndex}-${playlistItemIndex}`} className={classes.Image}>
              {thumbnailField && thumbnailField[0].url ? <img src={thumbnailField[0].url} alt="Thumbnail" /> : null}
            </div>
          );
        });

      return (
        <div key={`playlist-${playlistIndex}`} onClick={() => navigateToPage({ playlistID: playlist.id})} className={classes.Playlist}>
          <div className={classes.IconEnterBlock}>
            <img src={iconEnterBlockArrow} alt="Enter demo" />
          </div>
          <div className={classes.ImageContainer}>
            {thumbnails}
          </div>
          <div className={classes.DescriptionContainer}>
            <div className={classes.Title}>{_.get(fields, 'name', 'No name')}</div>
            {/* <div className={classes.Hashtags}>{_.get(fields, 'tagNames', []).map(t => '#'+t+' ').join('')}</div> */}
            <div className={classes.Creator}>Created by {_.get(fields, 'createdBy', '')}</div>
          </div>
        </div>
      )
    })
  }

  let searchbar = null
  if(searchbarActivated) {
    searchbar = (
      <div className={classes.Searchbar}>
        <img src={iconSearch} alt="Searchbar icon" />
        <input type="text" placeholder="Search" value={search} onChange={(e) => changeSearch(e.target.value.toLowerCase())} />
      </div>
    )
  }

  return (
    <div>
      {searchbar}
      <div className={classes.PageTitle}>Playlists</div>
      <div className={classes.Playlists}>
        {playlistBlocks}
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    playlists: state.airtableReducer.playlists,
    playlistItems: state.airtableReducer.playlistItems,
  }
}

export default connect(mapStateToProps, null)(Playlists)
