import * as actionTypes from '../actions/actionTypes.js'
import { updateObject } from '../utility'

const initialState = {
  demos: [],
  playlists: [],
  protos: [],
  playlistItems: [],
  medias: [],
  visitors: [],
  demonstrators: [],
  responsibles: [],
  feedbacks: [],
  technologies: [],
  sessions: [],
  tags: [],
  projects: [],
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.FETCH_DEMOS_SUCCESS:
      return updateObject(state, {
        demos: action.demos
      })
    case actionTypes.FETCH_SESSIONS_SUCCESS:
      return updateObject(state, {
        sessions: action.sessions
      })
    case actionTypes.FETCH_PROTOS_SUCCESS:
      return updateObject(state, {
        protos: action.protos
      })
    case actionTypes.FETCH_PROJECTS_SUCCESS:
      return updateObject(state, {
        projects: action.projects
      })
    case actionTypes.FETCH_PLAYLISTS_SUCCESS:
      return updateObject(state, {
        playlists: action.playlists
      })
    case actionTypes.FETCH_PLAYLIST_ITEMS_SUCCESS:
      return updateObject(state, {
        playlistItems: action.playlistItems
      })
    case actionTypes.FETCH_MEDIAS_SUCCESS:
      return updateObject(state, {
        medias: action.medias
      })
    case actionTypes.FETCH_VISITORS_SUCCESS:
      return updateObject(state, {
        visitors: action.visitors
      })
    case actionTypes.FETCH_DEMONSTRATORS_SUCCESS:
      return updateObject(state, {
        demonstrators: action.demonstrators
      })
    case actionTypes.FETCH_RESPONSIBLES_SUCCESS:
      return updateObject(state, {
        responsibles: action.responsibles
      })
    case actionTypes.FETCH_FEEDBACKS_SUCCESS:
      return updateObject(state, {
        feedbacks: action.feedbacks
      })
    case actionTypes.FETCH_TECHNOLOGIES_SUCCESS:
      return updateObject(state, {
        technologies: action.technologies
      })
    case actionTypes.FETCH_TAGS_SUCCESS:
      return updateObject(state, {
        tags: action.tags
      })
    default:
      return updateObject(state)
  }
}

export default reducer
