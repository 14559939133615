import React, { useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import placeholderImage from "shared/icons/placeholder.svg";

import iconSearch from "shared/icons/search.svg";
import iconIncompleteHide from "shared/icons/incomplete_hide.svg";
import iconIncompleteShow from "shared/icons/incomplete_show.svg";

import classes from "./Protos.module.sass";
import PlayablePreview from "../../components/PlayablePreview/PlayablePreview";
import TagFilter from "../../components/TagFilter/TagFilter";
import Dropdown from "../../components/Dropdown/Dropdown";

const Protos = ({ protos, searchbarActivated, isHomepage, technologies, tags }) => {
  const [search, changeSearch] = useState("");
  const [engineFilter, changeEngineFilter] = useState();
  const [technologyFilter, changeTechnologyFilter] = useState();
  const [interactionFilter, changeInteractionFilter] = useState();
  const [tagFilter, changeTagFilter] = useState();
  const [showIncompleteProtos, changeShowIncompleteProtos] = useState(true);

  let interactionDropdown = null;
  let engineDropdown = null;
  let technologyDropdown = null;

  const engines = protos.reduce((engineList, proto) => {
    const engine = proto.fields.engine;
    if (engine && engineList.indexOf(engine) < 0) {
      engineList = [...engineList, engine];
    }
    return engineList;
  }, []);

  const interactions = protos.reduce((interactionList, proto) => {
    if (proto.fields.interaction) {
      proto.fields.interaction.forEach((interaction) => {
        if (interactionList.indexOf(interaction) < 0) {
          interactionList = [...interactionList, interaction];
        }
      });
    }
    return interactionList;
  }, []);

  let protoBlocks = null;
  if (protos) {
    // MANDATORY - fields.uuid means the proto is associated with a project (TouchDesigner, Unity, Unreal)
    let filteredProtos = protos.filter((p) => p.fields.uuid);

    filteredProtos = protos.filter((p) => p?.fields?.locations?.indexOf(process.env.REACT_APP_OFFICE_LOCATION) >= 0);
    if (search != "") {
      filteredProtos = filteredProtos.filter((proto) =>
        _.get(proto.fields, "name", "").toLowerCase().includes(search)
      );
    }
    if (engineFilter) {
      filteredProtos = filteredProtos.filter((proto) => _.get(proto.fields, "engine", "") === engineFilter);
    }
    if (technologyFilter) {
      filteredProtos = filteredProtos.filter((proto) =>
        _.get(proto.fields, "technologies", "").includes(technologyFilter)
      );
    }
    if (interactionFilter) {
      filteredProtos = filteredProtos.filter((proto) =>
        _.get(proto.fields, "interaction", "").includes(interactionFilter)
      );
    }
    if (tagFilter) {
      filteredProtos = filteredProtos.filter((proto) => _.get(proto.fields, "tags", "").includes(tagFilter));
    }
    if (!showIncompleteProtos) {
      filteredProtos = filteredProtos.filter((proto) => _.get(proto.fields, "status") === "curated");
    }

    //Homepage showcases 6 most recent protos
    if (isHomepage) {
      filteredProtos = filteredProtos
        .sort((a, b) => new Date(b.fields.created) - new Date(a.fields.created))
        .slice(0, 6);
    } else {
      //Otherwise order them by alphabetical order
      filteredProtos = filteredProtos.sort(
        (a, b) => _.get(a, "fields.name", "-").charCodeAt(0) - _.get(b, "fields.name", "-").charCodeAt(0)
      );

      engineDropdown = (
        <Dropdown
          value={engineFilter}
          placeholder="Engine"
          onChange={(value) => changeEngineFilter(value)}
          options={engines.map((engine) => ({
            text: engine,
            value: engine,
          }))}
        />
      );

      interactionDropdown = (
        <Dropdown
          value={interactionFilter}
          placeholder="Interaction"
          onChange={(value) => changeInteractionFilter(value)}
          options={interactions.map((interaction) => ({
            text: interaction,
            value: interaction,
          }))}
        />
      );

      technologyDropdown = (
        <Dropdown
          value={technologyFilter}
          placeholder="Technology"
          onChange={(value) => changeTechnologyFilter(value)}
          options={technologies.map((technology) => ({
            value: technology.id,
            text: _.get(technology, "fields.name", ""),
          }))}
        />
      );
    }
    protoBlocks = filteredProtos.map((proto, i) => {
      let fields = proto.fields;
      return (
        <PlayablePreview
          key={`proto-${i}`}
          size="L"
          navigationData={{ protoID: proto.id }}
          thumbnail={_.get(fields, "thumbnail[0].url", placeholderImage)}
          title={_.get(fields, "name", "")}
          isIncomplete={fields.status && fields.status !== "curated"}
        />
      );
    });
  }

  let searchbar = null;
  if (searchbarActivated) {
    searchbar = (
      <div className={classes.Searchbar}>
        <img src={iconSearch} alt="Searchbar icon" />
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => changeSearch(e.target.value.toLowerCase())}
        />
      </div>
    );
  }

  return (
    <div>
      <div className={classes.SearchActions}>
        {searchbar}
        {engineDropdown}
        {technologyDropdown}
        {interactionDropdown}
      </div>
      {!isHomepage && (
        <div className={classes.Filters}>
          {tags.filter(tag => tag.fields.uiIconKey).map((tag) => (
            <TagFilter
              key={tag.id}
              tag={tag}
              onClick={() => changeTagFilter(tagFilter === tag.id ? "" : tag.id)}
              isActive={tagFilter === tag.id}
            />
          ))}
        </div>
      )}
      <div className={classes.PageHeaderBar}>
        <div className={classes.PageTitle}>{isHomepage ? "Latest Protos" : "Protos"}</div>
        {!isHomepage && (
          <button
            className={classes.ToggleCuratedButton}
            onClick={() => changeShowIncompleteProtos(!showIncompleteProtos)}
          >
            <img
              src={showIncompleteProtos ? iconIncompleteHide : iconIncompleteShow}
              className={classes.ToggleCuratedIcon}
            />
            {showIncompleteProtos ? "Hide Incomplete protos" : "Show Incomplete Protos"}
          </button>
        )}
      </div>
      <div className={classes.Protos}>{protoBlocks}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tags: state.airtableReducer.tags,
    protos: state.airtableReducer.protos,
    technologies: state.airtableReducer.technologies,
  };
};

export default connect(mapStateToProps, null)(Protos);
