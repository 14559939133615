import React from 'react'

import classes from './DrawerToggle.module.sass'

const drawerToggle = ({ clicked }) => (
  <div className={classes.DrawerToggle} onClick={clicked}>
    <svg width="31" height="24" viewBox="0 0 31 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1L31 1" stroke="white" stroke-width="2" />
      <path d="M0 12H31" stroke="white" stroke-width="2" />
      <path d="M0 23H14" stroke="white" stroke-width="2" />
    </svg>
  </div>
);

export default drawerToggle
