import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";

import GoogleAuthProvider from "./utils/GoogleAuthProvider";

/**
 * IMPORTANT NOTES
 *
 * ----------------------------
 * NEED TO BE ITS OWN COMPONENT
 * ----------------------------
 * Searchbar
 * Media block
 *
 */

/**
 * Redux store is initialized in its own file
 * This way you can also use it outside of React components if you want.
 */
import store from "./store-redux";

const GoogleAuthProviderIfEnabled = ({ children }) => {
  if (process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID) {
    return <GoogleAuthProvider>{children}</GoogleAuthProvider>;
  }

  return children;
};

const app = (
  <Provider store={store}>
    <GoogleAuthProviderIfEnabled>
      <App />
    </GoogleAuthProviderIfEnabled>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
