import * as actionTypes from '../actions/actionTypes.js'
import { updateObject } from '../utility'
import { update } from 'lodash'

const initialState = {
  mqttClient: null,
  online: false,
  presets: [],
  currentMedia: null,
  currentDemo: null,
  currentPreset: null,
  currentProto: null,
  currentProject: null,
  currentNavigation: null,
  currentVolume: null,
  currentGuestsCheckedInIds: []
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.MQTT_VOLUME:
    case actionTypes.MQTT_VOLUME:
      return updateObject(state, {
        currentVolume: action.volume
      })
    case actionTypes.MQTT_CURRENT_GUESTS_CHECKED_IN_IDS:
      return updateObject(state, {
        currentGuestsCheckedInIds: action.payload.sessionIds
      })
    case actionTypes.CREATE_MQTT_CLIENT:
      return updateObject(state, {
        mqttClient: action.mqttClient
      })
    case actionTypes.CHANGE_MQTT_CLIENT_STATE:
      return updateObject(state, {
        online: action.state
      })
    //MQTT OUT
    case actionTypes.MQTT_CURRENT_PRESET:
      return updateObject(state, {
        currentPreset: action.preset
      })

    case actionTypes.MQTT_CURRENT_PROJECT:
      return updateObject(state, {
        currentProject: action.project,
      })

    case actionTypes.MQTT_CURRENT_NAVIGATION:
      return updateObject(state, {
        currentNavigation: action.payload,
      })

    case actionTypes.MQTT_CURRENT_PROTO:
      return updateObject(state, {
        currentProto: action.proto,
      })
    case actionTypes.MQTT_CURRENT_MEDIA:
      return updateObject(state, {
        currentMedia: action.media,
      })
    case actionTypes.MQTT_CURRENT_DEMO:
      return updateObject(state, {
        currentDemo: action.demo,
        currentProto: null
      })
    // case actionTypes.MQTT_DEMO_STATE:
    // case actionTypes.MQTT_CURRENT_GUESTS:
    //MQTT IN
    case actionTypes.MQTT_CURRENT_PROTO_PRESETS:
      let presets = 'presets' in action.payload ? action.payload.presets : []
      return updateObject(state, {
        presets
      })
    case actionTypes.MQTT_DISPLAY_SENSORS_INFOS:
    case actionTypes.MQTT_IS_TRANSITION:
    case actionTypes.MQTT_STATS:
    case actionTypes.MQTT_UPDATE:
      //NEEDS TO BE IMPLEMENTED
      return updateObject(state)
    default:
      return updateObject(state)
  }
}

export default reducer
