import React from "react";
import _ from "lodash";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";

import classes from "./SidebarPlaylist.module.sass";

import PlayablePreview from "../PlayablePreview/PlayablePreview";
import placeholderImage from "shared/icons/placeholder.svg";

const Playlist = ({ demoID, protoID, projectID, playlist, playlistItems, currentProto, currentProject }) => {
  let protoBlocks = null;

  const playlistItemsInPlaylist =
    playlist.fields.playlistItems &&
    playlist.fields.playlistItems
      .map((playlistItemID) => playlistItems.find((playlistItem) => playlistItem.id === playlistItemID))
      .filter((playlistItem) => typeof playlistItem !== "undefined");

  protoBlocks = playlistItemsInPlaylist && playlistItemsInPlaylist.map((playlistItem) => {
    return (
      <PlayablePreview
        key={"playlistItem-" + playlistItem.id}
        size="M"
        isActive={
          (protoID && playlistItem.fields.proto && playlistItem.fields.proto[0] === protoID) ||
          (projectID && playlistItem.fields.project && playlistItem.fields.project[0] === projectID)
        }
        isPlaying={
          (currentProto && playlistItem.fields.proto && currentProto.id === playlistItem.fields.proto[0]) ||
          (currentProject && playlistItem.fields.project && currentProject.id === playlistItem.fields.project[0])
        }
        navigationData={{
          demoID: demoID,
          playlistID: playlist.id,
          playlistItemID: playlistItem.id,
        }}
        thumbnail={
          (playlistItem.fields.protoThumbnail && playlistItem.fields.protoThumbnail[0].url) ||
          (playlistItem.fields.projectThumbnail && playlistItem.fields.projectThumbnail[0].url) ||
          placeholderImage
        }
        title={playlistItem.fields.name}
        type={playlistItem.fields.protoThumbnail && "proto" || playlistItem.fields.projectThumbnail && "project"}
        isIncomplete={
          (playlistItem.fields.protoStatus && playlistItem.fields.protoStatus.indexOf("curated") < 0) ||
          (playlistItem.fields.projectStatus && playlistItem.fields.projectStatus.indexOf("curated") < 0)
        }
      />
    );
  });

  return (
    <div className={classes.Playlist}>
      <div className={classes.PlaylistHeader}>
        <div className={classes.PlaylistHeaderTitle}>Playlist</div>
        <div className={classes.PlaylistName}>{_.get(playlist, "fields.name", "No name")}</div>
      </div>
      <div className={classes.ProtosAndTransitions}>{protoBlocks}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    protos: state.airtableReducer.protos,
    playlistItems: state.airtableReducer.playlistItems,
    currentProto: state.mqttReducer.currentProto,
    currentProject: state.mqttReducer.currentProject,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMQTTCurrentProto: (proto) => {
      return dispatch(actions.sendMQTTCurrentProto(proto));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Playlist);
