import React, { useState, useEffect } from 'react'
import * as actions from '../../../store/actions/index'
import iconVolume from 'shared/icons/volume.svg'
import { connect } from 'react-redux'

import classes from './VolumeScrub.module.sass'

const clamp = (value, min, max) => {
  return Math.min(max, Math.max(min, value));
}

const VolumeScrub = ({currentVolume, sendMQTTCurrentVolume}) => {
  const [volumeLevel, changeVolumeLevel] = useState(currentVolume)
  const [scrubbing, changeScrubbingState] = useState(false)

  const scrubbingStateRef = React.useRef(scrubbing);
  const setScrubbingState = state => {
    scrubbingStateRef.current = state;
    changeScrubbingState(state);
  };

  useEffect(() => {
    changeVolumeLevel(currentVolume)
    return () => {
      //nothing
    }
  }, [currentVolume])

  useEffect(() => {
    const volumeScrub = document.getElementById('volume-scrub')
    volumeScrub.addEventListener('mousedown', () => setScrubbingState(true));
    volumeScrub.addEventListener('touchstart', () => setScrubbingState(true), {passive: true});
    window.addEventListener('mouseup', (e) => setScrubbingState(false));
    window.addEventListener('touchend', () => setScrubbingState(false), {passive: true});
    volumeScrub.addEventListener('mousemove', (e) => updateVolumeLevel(e))
    volumeScrub.addEventListener('touchmove', (e) => updateVolumeLevel(e), {passive: true})
    volumeScrub.addEventListener('click', (e) => updateVolumeLevel(e))
    volumeScrub.addEventListener('touch', (e) => updateVolumeLevel(e))
    return () => {
      volumeScrub.removeEventListener('mousedown', () => setScrubbingState(true))
      volumeScrub.addEventListener('touchstart', () => changeScrubbingState(true), {passive: true});
      volumeScrub.removeEventListener('mouseup', () => setScrubbingState(false))
      volumeScrub.removeEventListener('touchend', () => changeScrubbingState(false), {passive: true});
      window.removeEventListener('mousemove', (e) => updateVolumeLevel(e))
      volumeScrub.removeEventListener('touchmove', (e) => updateVolumeLevel(e), {passive: true})
      volumeScrub.removeEventListener('click', (e) => updateVolumeLevel(e))
      volumeScrub.removeEventListener('touch', (e) => updateVolumeLevel(e))
    }
  }, [])

  const updateVolumeLevel = (e) => {
    if(scrubbingStateRef.current) {
      const volumeScrub = document.getElementById('volume-scrub')
      const rect = volumeScrub.getBoundingClientRect();
      let leftPosition = rect.left
      let totalWidthScrub = rect.width;
      let clickedPosition = e.clientX || e.touches[0].clientX
      let clickedPositionRelativeToScrub = clickedPosition - leftPosition
      let newVolumeLevelPercentage = clamp(clickedPositionRelativeToScrub / totalWidthScrub, 0, 1) * 100;
      changeVolumeLevel(newVolumeLevelPercentage)
      sendMQTTCurrentVolume(Math.round(newVolumeLevelPercentage))
    }
  }

  return (
    <div id="volume-scrub" className={classes.VolumeLevelContainer}>
      <div style={{width: `${volumeLevel}%`}} className={classes.VolumeLevel}></div>
      <img className={classes.VolumeIcon} src={iconVolume} alt="Monitor volume" />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentVolume: state.mqttReducer.currentVolume
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sendMQTTCurrentVolume: (volume) => {
      return dispatch(actions.sendMQTTCurrentVolume(volume))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VolumeScrub)
