import React from "react";
import { connect } from "react-redux";
import _ from 'lodash'
import Proto from "../Proto/Proto";
import Project from "../Project/Project";

function PlaylistItem({ playlistID, playlistItemID, playlistItems }) {
  const playlistItem = playlistItems.find((playlistItem) => playlistItem.id === playlistItemID);
  if (_.get(playlistItem, 'fields.proto')) {
    return <Proto protoID={playlistItem.fields.proto[0]} playlistID={playlistID} playlistItemID={playlistItemID} />;
  } else if (_.get(playlistItem, 'fields.project')) {
    return <Project projectID={_.get(playlistItem, 'fields.project[0]')} playlistID={playlistID} playlistItemID={playlistItemID} />;
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    playlistItems: state.airtableReducer.playlistItems,
  };
};

export default connect(mapStateToProps, null)(PlaylistItem);
