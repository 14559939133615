import React from "react";
import classes from "./Software.module.sass";

import iconUnityLogo from "shared/icons/unity_logo.svg";
import iconTouchDesignerLogo from "shared/icons/touchdesigner_logo.svg";

function Software({ engine }) {
  return (
    <div className={classes.SoftwareContainer}>
      <div className={classes.ContainerTitle}>Software</div>
      <div className={classes.Software}>
        <div className={classes.SoftwareLogo}>
          {(engine === "Unity" && <img src={iconUnityLogo} alt="Software logo" />) ||
            (engine === "TouchDesigner" && <img src={iconTouchDesignerLogo} alt="Software logo" />)}
        </div>
        <div className={classes.SoftwareName}>{engine}</div>
      </div>
    </div>
  );
}

export default Software;
