import React, { useState, useEffect } from 'react'
import { Progress } from 'antd'

import warningSignIcon from 'shared/icons/warning_sign.svg'

import classes from './WarningNotice.module.sass'

let count = 0

const WarningNotice = () => {
  const [visible, changeVisibility] = useState(true)
  const [closeButtonShown, changeCloseButtonVisibility] = useState(true)
  const [progressValue, changeProgressValue] = useState(0)

  //uncomment to have loading bar
  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     if(count < 100) {
  //       count = count+1
  //     }
  //     changeProgressValue(count)
  //   }, 60);
  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [])

  const changeVisibilityAction = (event) => {
    if(event.target === event.currentTarget) {
      changeVisibility(false)
    }
  }

  useEffect(() => {
    if(progressValue === 100) {
      // clearInterval(interval)
      changeCloseButtonVisibility(true)
    }
    return () => {
      //nothing for now
    }
  }, [progressValue])

  let closeButton = null
  if(closeButtonShown) {
    closeButton = (
      <div className={classes.WarningNoticeButton} onClick={() => changeVisibility(false)}>IT'S FINE</div>
    )
  }

  return (
    <div
      style={{display: visible ? 'flex' : 'none'}}
      className={classes.WarningNoticeContainer}
      // onClick={(e) => changeVisibilityAction(e)}
      >
      <div className={classes.WarningNotice}>
        <div className={classes.WarningNoticeHeader}>
          <img src={warningSignIcon} alt="Warning sign" />
          <div>BE CAREFUL</div>
        </div>
        <div className={classes.WarningNoticeMessage}>
          <span>Someone is in the protoroom and a demo might be happening. Please confirm that you can play with the tablet without disturbing anything.</span>
          <br />
          <span style={{textAlign: 'center', width: '100%'}}>Thanks</span>
        </div>
        <div className={classes.WarningNoticeAction}>
          <Progress
            style={{display: closeButtonShown ? 'none' : 'block'}}
            strokeLinecap="square"
            percent={progressValue}
            strokeColor={'white'}
            showInfo={false}
            strokeWidth={5} />
          {closeButton}
        </div>
      </div>
    </div>
  )
}

export default WarningNotice
