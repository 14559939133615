import React from "react";

import classes from "./PlayablePreview.module.sass";

import iconEnterBlockArrow from "shared/icons/enter_block_arrow.svg";
import iconProtoLogo from "shared/icons/proto_logo.svg";
import iconProjectLogo from "shared/icons/project_logo.svg";

import { navigateToPage } from "../../utils/utils";

function PlayablePreview({ size = "M", navigationData, isActive, isPlaying, isIncomplete, title, thumbnail, type }) {
  const sizes = {
    M: {
      PlaylistItem: 154,
      ImageContainer: 112,
      DescriptionContainer: 42,
      Title: classes.TitleM,
    },
    L: {
      PlaylistItem: 220,
      ImageContainer: 160,
      DescriptionContainer: 60,
      Title: classes.TitleL,
    },
  };

  let activeClass = "";
  if (isActive) {
    activeClass = classes.Active;
  }
  if (isPlaying) {
    activeClass = classes.Playing;
  }

  function onClick() {
    navigateToPage(navigationData);
  }

  return (
    <div className={classes.Wrapper + " " + activeClass}>
      <div className={classes.PlayablePreview} onClick={onClick} style={{ height: sizes[size].PlaylistItem }}>
        <div className={classes.IconEnterBlock}>
          <img src={iconEnterBlockArrow} alt="Enter demo" />
        </div>
        <div
          className={classes.ImageContainer}
          style={{ backgroundImage: `url('${thumbnail}')`, height: sizes[size].ImageContainer }}
        >
          {isIncomplete ? <div className={classes.StatusNotCurated}>INCOMPLETE</div> : null}
        </div>
        <div className={classes.DescriptionContainer} style={{ height: sizes[size].DescriptionContainer }}>
          <img className={classes.Logo} src={type === "proto" && iconProtoLogo || type === "project" && iconProjectLogo || iconProtoLogo} alt="Logo Proto" />
          <div className={sizes[size].Title}>{title}</div>
        </div>
      </div>
    </div>
  );
}

export default PlayablePreview;
