import React, { useContext } from "react";

import { navigateToPage } from "../../../utils/utils";
import iconNavHome from "shared/icons/nav_home.svg";
import iconNavDemos from "shared/icons/nav_demos.svg";
import iconNavPlaylists from "shared/icons/nav_playlists.svg";
import iconNavProtos from "shared/icons/nav_protos.svg";
import iconNavProjects from "shared/icons/nav_projects.svg";
import iconNavMedias from "shared/icons/nav_medias.svg";
import iconNavInputs from "shared/icons/nav_inputs.svg";
import iconNavLogout from "shared/icons/nav_logout.svg";

import { GoogleAuthContext } from "../../../utils/GoogleAuthProvider";

import classes from "./NavigationItems.module.sass";

function NavigationItems() {
  const { gapi } = useContext(GoogleAuthContext);

  function logout() {
    gapi.auth2.getAuthInstance().signOut();
  }

  const hasDemos = process.env.REACT_APP_OFFICE_LOCATION === "Montreal";
  const hasGoogleSignIn = typeof process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID !== "undefined";

  return (
    <ul className={classes.NavigationItems}>
      <div className={classes.NavigationItem} onClick={() => navigateToPage({ path: "/" })}>
        <img src={iconNavHome} alt="Go to home" />
        <div>Home</div>
      </div>
      {hasDemos && (
        <div className={classes.NavigationItem} onClick={() => navigateToPage({ path: "/demos" })}>
          <img src={iconNavDemos} alt="Go to demos" />
          <div>Demos</div>
        </div>
      )}
      <div className={classes.NavigationItem} onClick={() => navigateToPage({ path: "/protos" })}>
        <img src={iconNavProtos} alt="Go to protos" />
        <div>Prototypes</div>
      </div>
      <div className={classes.NavigationItem} onClick={() => navigateToPage({ path: "/projects" })}>
        <img src={iconNavProjects} alt="Go to projects" />
        <div>Projects</div>
      </div>
      <div className={classes.NavigationItem} onClick={() => navigateToPage({ path: "/playlists" })}>
        <img src={iconNavPlaylists} alt="Go to playlists" />
        <div>Playlists</div>
      </div>
      <div className={classes.NavigationItem} onClick={() => navigateToPage({ path: "/medias" })}>
        <img src={iconNavMedias} alt="Go to medias" />
        <div>Medias</div>
      </div>
      <div className={classes.NavigationItem} onClick={() => navigateToPage({ protoID: "rec5HlbheAlQtnKoT" })}>
        <img src={iconNavInputs} alt="Go to inputs" />
        <div>Inputs</div>
      </div>
      <div style={{ flexGrow: 1 }} />
      {hasGoogleSignIn && (
        <div className={classes.NavigationItem + " " + classes.NavigationItemLast} onClick={() => logout()}>
          <img src={iconNavLogout} alt="Logout" />
          <div>Logout</div>
        </div>
      )}
    </ul>
  );
}

export default NavigationItems;
