import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { useLocation } from "@reach/router";

import * as actions from "../../store/actions/index";
import SidebarPlaylist from "../../components/SidebarPlaylist/SidebarPlaylist";
import { navigateToPage } from "../../utils/utils";
import playIcon from "shared/icons/play.svg";
import stopButtonIcon from "shared/icons/stop_button.svg";
import placeholderImage from "shared/icons/placeholder.svg";
import iconEnterBlockArrow from "shared/icons/enter_block_arrow.svg";
import iconTechnology from "shared/icons/technology.svg";
import iconArrowNotes from "shared/icons/arrow_notes.svg";

import iconMediaGradientCircle from "shared/icons/media_gradient_circle.svg";
import iconMediaBackward from "shared/icons/media_backward.svg";
import iconMediaForward from "shared/icons/media_forward.svg";

import classes from "./Project.module.sass";
import PlayablePreview from "components/PlayablePreview/PlayablePreview";
import GalleryTab from "components/MediaGallery/GalleryTab";
import Software from "components/ProtoOrProject/Software";
import { Collapse } from "antd";

const Project = ({
  changeNavigationPage,
  projects,
  technologies,
  currentDemo,
  getItemByID,
  sendMQTTCurrentDemo,
  sendMQTTCurrentProject,
  sendMQTTCurrentProto,
  sendMQTTCurrentMedia,
  currentProject,
  projectID,
  playlistID,
  demoID,
  playlistItemID,
  sendMQTTCurrentNavigation,
  sendMQTTScrubMedia,
}) => {
  const location = useLocation();
  const refLeftContent = useRef();

  useEffect(() => {
    changeNavigationPage({ projectID, playlistID, playlistItemID });
  }, [projectID, playlistID, playlistItemID]);

  useEffect(() => {
    if (!refLeftContent.current) return;
    refLeftContent.current.scrollTo(0, 0);
  }, [location.pathname]);

  //Load project with projectID found in URL
  const [project, changeProject] = useState(null);
  useEffect(() => {
    const activeProject = projects.find((p) => p.id === projectID);
    changeProject(activeProject);
    return () => {
      changeProject(null);
    };
  }, [currentProject, projects, projectID]);

  const [tab, changeTab] = useState("infos");

  const launchTechnology = (technology) => {
    let protoID = _.get(technology, "fields.showcaseProto[0]", "");
    if (protoID) {
      navigateToPage({ protoID });
    }
  };

  const startCurrentProject = (project) => {
    sendMQTTCurrentProto(null, {});
    sendMQTTCurrentMedia(null, {});
    if (project === null) {
      sendMQTTCurrentProject(null, {});
      sendMQTTCurrentMedia(null, {});
    } else {
      if (demoID && (!currentDemo || (currentDemo && currentDemo.id !== demoID))) {
        let demo = getItemByID("demos", demoID);
        sendMQTTCurrentDemo(demo);
      }
      //sending also the ids for navigation back to project
      sendMQTTCurrentProject(project);
      sendMQTTCurrentNavigation({ playlistID, playlistItemID, projectID, demoID });
    }
  };

  let sidebarPlaylist = null;
  let content = <div>No project with this ID found !</div>;
  if (project) {
    let fields = project.fields;
    let currentTab = null;
    let currentPlaylist = playlistID ? getItemByID("playlists", playlistID) : null;

    if (currentPlaylist) {
      sidebarPlaylist = (
        <div className={classes.RightContent}>
          <SidebarPlaylist playlist={currentPlaylist} projectID={projectID} playlistID={playlistID} demoID={demoID} />
        </div>
      );
    } else {
      sidebarPlaylist = null;
    }

    let associatedTechnologies = null;
    let projectTechnologies = _.get(fields, "technologies", []);
    if (technologies && technologies.length && projectTechnologies && projectTechnologies.length) {
      let technologies = projectTechnologies.map((t) => getItemByID("technologies", t));
      associatedTechnologies = technologies.map((technology, i) => {
        let iconEnterTech = null;
        if (_.get(technology, "fields.showcaseProto[0]", "")) {
          iconEnterTech = (
            <div className={classes.IconEnterBlock}>
              <img src={iconEnterBlockArrow} alt="Enter technology" />
            </div>
          );
        }
        return (
          <div className={classes.Technology} key={`tech-${i}`} onClick={() => launchTechnology(technology)}>
            <div className={classes.IconTechnology}>
              <img src={iconTechnology} alt="Enter technology" />
            </div>
            {iconEnterTech}
            <div className={classes.Title}>{_.get(technology, "fields.name", "no name")}</div>
          </div>
        );
      });
    }

    if (tab === "infos") {
      const relatedProjectsData = _.get(fields, "relatedPrototypes", []).map((rp) => getItemByID("projects", rp));
      let relatedProjects = null;
      relatedProjects = (
        <div className={classes.Row4}>
          <div className={classes.RelatedProjects}>
            {relatedProjectsData.map((project, i) => {
              let fields = project.fields;
              let navigationData;
              if (!demoID && !playlistID) {
                navigationData = { projectID: project.id };
              } else if (!demoID && playlistID) {
                navigationData = { projectID: project.id, playlistID };
              } else {
                navigationData = { projectID: project.id, playlistID, demoID };
              }
              return (
                <PlayablePreview
                  key={`project-${i}`}
                  navigationData={navigationData}
                  isIncomplete={fields.status && fields.status !== "curated"}
                  thumbnail={_.get(fields, "thumbnail[0].url", placeholderImage)}
                  title={_.get(fields, "name", "")}
                />
              );
            })}
          </div>
        </div>
      );

      currentTab = (
        <div className={classes.Infos}>
          <div className={classes.Row2}>
            <div className={classes.NotesContainer}>
              <Collapse defaultActiveKey={["notes"]}>
                {_.get(fields, "howTo", "") && (
                  <Collapse.Panel header="How To" key="howTo">
                    <div className={classes.Notes}>{_.get(fields, "howTo", "")}</div>
                  </Collapse.Panel>
                )}
                {_.get(fields, "notes", "") && (
                  <Collapse.Panel header="Notes" key="notes">
                    <div className={classes.Notes}>
                      {_.get(fields, "notes", "")
                        .split("**")
                        .slice(1)
                        .map((line, i) => {
                          return (
                            <div key={`line-${i}`}>
                              <img src={iconArrowNotes} alt="arrow to identify statement" /> {line}
                              <br />
                              <br />
                            </div>
                          );
                        })}
                    </div>
                  </Collapse.Panel>
                )}
                {relatedProjectsData.length && (
                  <Collapse.Panel header="Similar to" key="related">
                    {relatedProjects}
                  </Collapse.Panel>
                )}
              </Collapse>
            </div>
            <div className={classes.TechnologiesAndSoftwares}>
              <div className={classes.TechnologiesContainer}>
                <div className={classes.ContainerTitle}>Technology</div>
                <div className={classes.Technologies}>{associatedTechnologies}</div>
              </div>
              <Software engine={_.get(fields, "engine", "No engine specified")} />
            </div>
          </div>
        </div>
      );
    } else if (tab === "gallery") {
      currentTab = <GalleryTab item={project} />;
    }

    let playButton = (
      <div className={classes.PlayButton} onClick={() => startCurrentProject(project)}>
        <img alt={"Play Icon"} src={playIcon} />
      </div>
    );
    if (currentProject && currentProject.id === projectID) {
      playButton = (
        <div className={classes.StopButton} onClick={() => startCurrentProject(null)}>
          <img alt={"Stop Icon"} src={stopButtonIcon} />
        </div>
      );
    }

    content = (
      <>
        <div
          ref={refLeftContent}
          className={classes.LeftContent + " " + (sidebarPlaylist === null ? classes.FullWidth : "")}
        >
          <div className={classes.ContentHeader}>
            <div className={classes.Actions}>
              {playButton}
              {currentProject?.id == projectID && (
                <>
                  <div className={classes.ActionsScrub}>
                    <div className={classes.BackwardButton} onClick={() => sendMQTTScrubMedia(-15)}>
                      <img
                        className={classes.BorderCircle}
                        alt={"Circle around button"}
                        src={iconMediaGradientCircle}
                      />
                      <img alt={"Backward Icon"} src={iconMediaBackward} />
                    </div>
                    <div className={classes.ForwardButton} onClick={() => sendMQTTScrubMedia(15)}>
                      <img
                        className={classes.BorderCircle}
                        alt={"Circle around button"}
                        src={iconMediaGradientCircle}
                      />
                      <img alt={"Forward Icon"} src={iconMediaForward} />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={classes.PreviewImageContainer}>
              <img
                className={classes.PreviewImage}
                alt="Preview of project"
                src={_.get(fields, "thumbnail[0].url", placeholderImage)}
              />
            </div>
            <div className={classes.HeaderInfos}>
              <div className={classes.Title}>{_.get(fields, "name", "No Name")}</div>
              <div className={classes.Tags}>
                {_.get(fields, "tagNames", [])
                  .map((t) => "#" + t + " ")
                  .join("")}
              </div>
            </div>
          </div>
          <div className={classes.Content}>
            <div className={classes.Tabs}>
              <div
                className={classes.Tab + " " + (tab === "infos" ? classes.Active : "")}
                onClick={() => changeTab("infos")}
              >
                <div>Infos</div>
              </div>
              <div
                className={classes.Tab + " " + (tab === "gallery" ? classes.Active : "")}
                onClick={() => changeTab("gallery")}
              >
                <div>Gallery</div>
              </div>
            </div>
            {currentTab}
          </div>
        </div>
        {sidebarPlaylist}
      </>
    );
  }

  return <div className={classes.ProjectContainer}>{content}</div>;
};

const mapStateToProps = (state) => {
  return {
    currentDemo: state.mqttReducer.currentDemo,
    projects: state.airtableReducer.projects,
    currentProject: state.mqttReducer.currentProject,
    technologies: state.airtableReducer.technologies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeNavigationPage: (ids) => {
      return dispatch(actions.changeNavigationPage(ids));
    },
    getItemByID: (model, id) => {
      return dispatch(actions.getItemByID(model, id));
    },
    sendMQTTCurrentProject: (project) => {
      return dispatch(actions.sendMQTTCurrentProject(project));
    },
    sendMQTTCurrentNavigation: (navigation) => {
      return dispatch(actions.sendMQTTCurrentNavigation(navigation));
    },
    sendMQTTCurrentDemo: (demo) => {
      return dispatch(actions.sendMQTTCurrentDemo(demo));
    },
    sendMQTTCurrentProto: (proto) => {
      return dispatch(actions.sendMQTTCurrentProto(proto));
    },
    sendMQTTCurrentMedia: (media) => {
      return dispatch(actions.sendMQTTCurrentMedia(media));
    },
    sendMQTTScrubMedia: (scrubValue) => {
      return dispatch(actions.sendMQTTScrubMedia(scrubValue));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
