import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as actions from "../../store/actions/index";

import classes from "./GalleryTab.module.sass";
import GallerySection from "./GallerySection";

function GalleryTab({ getItemByID, item }) {
  const getMediaItems = (filter) => {
    return _.get(item, "fields['medias']", [])
      .map((m) => getItemByID("medias", m))
      .filter((m) => typeof m !== "undefined")
      .filter((m) => m.fields.type === filter);
  };

  const photos = getMediaItems("Photo");
  const videos = getMediaItems("Video");
  const stats = getMediaItems("Stats");

  return (
    <div className={classes.Gallery}>
      {photos.length || videos.length || stats.length ? (
        <>
          {photos.length > 0 && <GallerySection title="Photos" items={photos} />}
          {videos.length > 0 && <GallerySection title="Videos" items={videos} />}
          {stats.length > 0 && <GallerySection title="Statistics" items={stats} />}
        </>
      ) : (
        "No media"
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getItemByID: (model, id) => {
      return dispatch(actions.getItemByID(model, id));
    },
  };
};

export default connect(null, mapDispatchToProps)(GalleryTab);
