import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import { navigateToPage } from "../../utils/utils";
import placeholderImage from "shared/icons/placeholder.svg";
import playIcon from "shared/icons/play.svg";
import PlayablePreview from "../../components/PlayablePreview/PlayablePreview";
import classes from "./Playlist.module.sass";

const Playlist = ({ playlists, playlistItems, demoID, playlistID }) => {
  const [playlist, changePlaylist] = useState(null);

  //Find the current playlist based on the URL playlistID
  useEffect(() => {
    const currentPlaylist = playlists.find((d) => d.id === playlistID);
    changePlaylist(currentPlaylist);
    return () => {
      changePlaylist(null);
    };
  }, [playlists, playlistID]);

  let content = <div>No playlist with this ID found !</div>;
  if (playlist) {
    let fields = playlist.fields;
    const playlistItemsInPlaylist =
      playlist.fields.playlistItems &&
      playlist.fields.playlistItems
        .map((playlistItemID) => playlistItems.find((playlistItem) => playlistItem.id === playlistItemID))
        .filter((playlistItem) => typeof playlistItem !== "undefined");
    let thumbnails =
      playlistItemsInPlaylist &&
      playlistItemsInPlaylist.slice(0, 4).map((playlistItem, playlistItemIndex) => {
        let thumbnailField = playlistItem.fields.protoThumbnail || playlistItem.fields.projectThumbnail;
        return (
          <div key={`${playlist}-${playlistItemIndex}`} className={classes.Image}>
            {thumbnailField && thumbnailField[0].url ? <img src={thumbnailField[0].url} alt="Thumbnail" /> : null}
          </div>
        );
      });

    content = (
      <>
        <div className={classes.Details}>
          <div className={classes.ImageContainer}>{thumbnails}</div>
          <div className={classes.KeyInfos}>
            <div className={classes.Title}>{_.get(fields, "name", "No name")}</div>
            <div className={classes.CreatedContainer}>
              <div className={classes.CreatedInfo}>
                <div className={classes.CreatedInfoText}>Created by {_.get(fields, "createdBy", "")}</div>
                <div className={classes.CreatedInfoDate}>
                  {new Date(_.get(fields, "created", 0)).toDateString()}
                </div>
              </div>
            </div>
            <div className={classes.Description}>{_.get(fields, "description")}</div>
          </div>
          <div
            className={classes.PlayButton}
            onClick={() => {
              navigateToPage({
                demoID: demoID,
                playlistID: playlist.id,
                playlistItemID: _.get(playlistItemsInPlaylist, "[0].id", null),
              });
            }}
          >
            <img alt={"Play Icon"} src={playIcon} />
          </div>
        </div>
        <div className={classes.Playlist}>
          {playlistItemsInPlaylist && playlistItemsInPlaylist.map((playlistItem) => {
            return (
              <PlayablePreview
                key={"playlistItem-" + playlistItem.id}
                size="L"
                navigationData={{
                  demoID: demoID,
                  playlistID: playlist.id,
                  playlistItemID: playlistItem.id,
                }}
                thumbnail={
                  (playlistItem.fields.protoThumbnail && playlistItem.fields.protoThumbnail[0].url) ||
                  (playlistItem.fields.projectThumbnail && playlistItem.fields.projectThumbnail[0].url) ||
                  placeholderImage
                }
                isIncomplete={
                  (playlistItem.fields.protoStatus && playlistItem.fields.protoStatus.indexOf("curated") < 0) ||
                  (playlistItem.fields.projectStatus && playlistItem.fields.projectStatus.indexOf("curated") < 0)
                }
                title={playlistItem.fields.name}
                type={
                  (playlistItem.fields.protoThumbnail && "proto") || (playlistItem.fields.projectThumbnail && "project")
                }
              />
            );
          })}
        </div>
      </>
    );
  }
  return <div className={classes.PlaylistContainer}>{content}</div>;
};

const mapStateToProps = (state) => {
  return {
    playlists: state.airtableReducer.playlists,
    playlistItems: state.airtableReducer.playlistItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getItemByID: (model, id) => {
      return dispatch(actions.getItemByID(model, id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Playlist);
