import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle'
import { connect } from 'react-redux'
import { navigateToPage } from '../../../utils/utils'
import * as actions from '../../../store/actions/index'

import iconBreadcrumbSeparator from 'shared/icons/breadcrumb_separator.svg'
import iconEnterBlockArrow from 'shared/icons/enter_block_arrow.svg'
import iconConnectionsGreen from 'shared/icons/connections_green.svg'

import classes from './Toolbar.module.sass'
import VolumeScrub from '../VolumeScrub/VolumeScrub'

const Toolbar = ({
  drawerToggleClicked,
  demoID,
  playlistID,
  playlistItemID,
  protoID,
  projectID,
  sessions,
  getItemByID,
  currentDemo,
  currentProto,
  currentProject,
  currentNavigation
}) => {
  const [currentDemoState, changeCurrentDemoState] = useState("");
  const [time, changeTime] = useState(new Date());

  useEffect(() => {
    let interval = setInterval(() => {
      changeTime(new Date());
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    changeCurrentDemoState(currentDemo);
    return () => {
      changeCurrentDemoState(null);
    };
  }, [currentDemo]);

  const demoVisitorIds =
    currentDemoState &&
    sessions
      .filter((session) => session.fields.visitId && session.fields.visitId.indexOf(currentDemoState.id) >= 0)
      .map((session) => {
        return session.fields.visitorId[0];
      });

  let navigationPages = null;
  if (demoID) {
    navigationPages = (
      <>
        <div className={classes.CurrentNavigationPage} onClick={() => navigateToPage({ path: "/demos" })}>
          <div>DEMOS</div>
        </div>
        <img src={iconBreadcrumbSeparator} alt="Breadcrumb separator" />
        <div className={classes.CurrentNavigationPage} onClick={() => navigateToPage({ demoID })}>
          <div>{_.get(getItemByID("demos", demoID), "fields.visitName", "DEMO").toUpperCase()}</div>
        </div>
        {playlistID ? <img src={iconBreadcrumbSeparator} alt="Breadcrumb separator" /> : null}
        {playlistID ? (
          <>
            <div className={classes.CurrentNavigationPage} onClick={() => navigateToPage({ demoID, playlistID })}>
              <div>{_.get(getItemByID("playlists", playlistID), "fields.name", "PLAYLIST").toUpperCase()}</div>
            </div>
            {protoID ? <img src={iconBreadcrumbSeparator} alt="Breadcrumb separator" /> : null}
          </>
        ) : null}
        {protoID ? (
          <>
            <div
              className={classes.CurrentNavigationPage}
              onClick={() => navigateToPage({ demoID, playlistID, playlistItemID, protoID })}
            >
              <div>{_.get(getItemByID("protos", protoID), "fields.name", "PROTO").toUpperCase()}</div>
            </div>
          </>
        ) : null}
      </>
    );
  } else if (playlistID) {
    navigationPages = (
      <>
        <div className={classes.CurrentNavigationPage} onClick={() => navigateToPage({ path: "/playlists" })}>
          <div>PLAYLISTS</div>
        </div>
        <img src={iconBreadcrumbSeparator} alt="Breadcrumb separator" />
        <div className={classes.CurrentNavigationPage} onClick={() => navigateToPage({ playlistID })}>
          <div>{_.get(getItemByID("playlists", playlistID), "fields.name", "PLAYLIST").toUpperCase()}</div>
        </div>
        {protoID ? <img src={iconBreadcrumbSeparator} alt="Breadcrumb separator" /> : null}
        {protoID ? (
          <>
            <div
              className={classes.CurrentNavigationPage}
              onClick={() => navigateToPage({ playlistID, playlistItemID, protoID })}
            >
              <div>{_.get(getItemByID("protos", protoID), "fields.name", "PROTO").toUpperCase()}</div>
            </div>
          </>
        ) : null}
      </>
    );
  } else if (protoID) {
    navigationPages = (
      <>
        <div className={classes.CurrentNavigationPage} onClick={() => navigateToPage({ path: "/protos" })}>
          <div>PROTOS</div>
        </div>
        <img src={iconBreadcrumbSeparator} alt="Breadcrumb separator" />
        <div className={classes.CurrentNavigationPage} onClick={() => navigateToPage({ protoID })}>
          <div>{_.get(getItemByID("protos", protoID), "fields.name", "PROTO").toUpperCase()}</div>
        </div>
      </>
    );
  } else if (projectID) {
    navigationPages = (
      <>
        <div className={classes.CurrentNavigationPage} onClick={() => navigateToPage({ path: "/projects" })}>
          <div>PROJECTS</div>
        </div>
        <img src={iconBreadcrumbSeparator} alt="Breadcrumb separator" />
        <div className={classes.CurrentNavigationPage} onClick={() => navigateToPage({ projectID })}>
          <div>{_.get(getItemByID("projects", projectID), "fields.name", "PROJECT").toUpperCase()}</div>
        </div>
      </>
    );
  }

  const navigateToConnections = () => {
    if (currentDemoState) {
      navigateToPage({ demoID: currentDemoState.id });
    }
  };

  const navigateToCurrentPlaylistItem = () => {
    navigateToPage(currentNavigation);
  };

  let currentProtoNav = null;
  if ((currentProto && currentProto.id !== protoID) || (currentProject && currentProject.id !== projectID)) {
    currentProtoNav = (
      <div onClick={() => navigateToCurrentPlaylistItem()} className={classes.CurrentProto}>
        <img className={classes.CurrentNavigationArrow} src={iconEnterBlockArrow} alt="Enter current playlist item" />
        {/* <div className={classes.CurrentNavigationLogo}><img src={iconProtoLogoWhite} alt="Logo proto" /></div> */}
        <div className={classes.CurrentNavigationText}>CURRENTLY PLAYING</div>
      </div>
    );
  }

  let connectionsBlock = null;
  if (currentDemoState) {
    connectionsBlock = (
      <div
        onClick={() => navigateToConnections()}
        className={classes.Connections + " " + (currentDemoState ? classes.ConnectionsActive : null)}
      >
        <img className={classes.ConnectionsArrow} src={iconEnterBlockArrow} alt="Go to current demo" />
        <img className={classes.ConnectionsIcon} src={iconConnectionsGreen} alt="Connections icon" />
        <div className={classes.Number}>{demoVisitorIds.length || 0}</div>
      </div>
    );
  }

  return (
    <header className={classes.Toolbar}>
      <DrawerToggle clicked={drawerToggleClicked} />
      <div className={classes.CurrentNavigation}>{navigationPages}</div>
      {currentProtoNav}
      {connectionsBlock}
      <div className={classes.Hour}>
        <div>{`${time.getHours().toString().padStart(2, "0")}:${time.getMinutes().toString().padStart(2, "0")}`}</div>
      </div>
      {!process.env.REACT_APP_HIDE_VOLUME_CONTROL || process.env.REACT_APP_HIDE_VOLUME_CONTROL !== "true" && (
        <div className={classes.Volume}>
          <VolumeScrub />
        </div>
      )}
    </header>
  );
};

const mapStateToProps = state => {
  return {
    demoID: state.applicationReducer.demoID,
    playlistID: state.applicationReducer.playlistID,
    playlistItemID: state.applicationReducer.playlistItemID,
    protoID: state.applicationReducer.protoID,
    projectID: state.applicationReducer.projectID,
    protos: state.airtableReducer.protos,
    playlists: state.airtableReducer.playlists,
    demos: state.airtableReducer.demos,
    sessions: state.airtableReducer.sessions,
    currentProto: state.mqttReducer.currentProto,
    currentProject: state.mqttReducer.currentProject,
    currentDemo: state.mqttReducer.currentDemo,
    currentNavigation: state.mqttReducer.currentNavigation,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getItemByID: (model, id) => {
      return dispatch(actions.getItemByID(model, id))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar)
