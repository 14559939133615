import React from 'react'
import navXIcon from 'shared/icons/nav_x.svg'

import NavigationItems from '../NavigationItems/NavigationItems'
import classes from './SideDrawer.module.sass'
import Backdrop from './Backdrop/Backdrop'

const sideDrawer = ({open, closed}) => {
  let attachedClasses = [classes.SideDrawer, classes.Close]
  if (open) {
    attachedClasses = [classes.SideDrawer, classes.Open]
  }
  return (
    <>
      <Backdrop show={open} clicked={closed} />
      <div className={attachedClasses.join(' ')} onClick={closed}>
        <div className={classes.DrawerHeader}>
          <div></div>
          <div className={classes.CloseButton}><img src={navXIcon} alt="Close drawer" /></div>
        </div>
        <nav>
          <NavigationItems />
        </nav>
      </div>
    </>
  )
}

export default sideDrawer
