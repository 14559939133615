import React, { useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import placeholderImage from "shared/icons/placeholder.svg";

import iconSearch from "shared/icons/search.svg";
import iconIncompleteHide from "shared/icons/incomplete_hide.svg";
import iconIncompleteShow from "shared/icons/incomplete_show.svg";

import classes from "./Projects.module.sass";
import PlayablePreview from "../../components/PlayablePreview/PlayablePreview";
import TagFilter from "../../components/TagFilter/TagFilter";
import Dropdown from "../../components/Dropdown/Dropdown";

const Projects = ({ projects, searchbarActivated, isHomepage, technologies, tags }) => {
  const [search, changeSearch] = useState("");
  const [engineFilter, changeEngineFilter] = useState();
  const [technologyFilter, changeTechnologyFilter] = useState();
  const [interactionFilter, changeInteractionFilter] = useState();
  const [tagFilter, changeTagFilter] = useState();
  const [showIncompleteProjects, changeShowIncompleteProjects] = useState(true);

  let interactionDropdown = null;
  let engineDropdown = null;
  let technologyDropdown = null;

  const interactions = projects.reduce((interactionList, project) => {
    if (project.fields.interaction) {
      project.fields.interaction.forEach((interaction) => {
        if (interactionList.indexOf(interaction) < 0) {
          interactionList = [...interactionList, interaction];
        }
      });
    }
    return interactionList;
  }, []);

  let projectBlocks = null;
  if (projects) {
    let filteredProjects = [...projects];
    if (search != "") {
      filteredProjects = filteredProjects.filter((project) =>
        _.get(project.fields, "name", "").toLowerCase().includes(search)
      );
    }
    if (engineFilter) {
      filteredProjects = filteredProjects.filter((project) => _.get(project.fields, "engine", "") === engineFilter);
    }
    if (technologyFilter) {
      filteredProjects = filteredProjects.filter((project) =>
        _.get(project.fields, "technologies", "").includes(technologyFilter)
      );
    }
    if (interactionFilter) {
      filteredProjects = filteredProjects.filter((project) =>
        _.get(project.fields, "interaction", "").includes(interactionFilter)
      );
    }
    if (tagFilter) {
      filteredProjects = filteredProjects.filter((project) => _.get(project.fields, "tags", "").includes(tagFilter));
    }
    if (!showIncompleteProjects) {
      filteredProjects = filteredProjects.filter((project) => _.get(project.fields, "status") === "curated");
    }

    //Homepage showcases 6 most recent projects
    if (isHomepage) {
      filteredProjects = filteredProjects
        .sort((a, b) => new Date(b.fields.created) - new Date(a.fields.created))
        .slice(0, 6);
    } else {
      //Otherwise order them by alphabetical order
      filteredProjects = filteredProjects.sort(
        (a, b) => _.get(a, "fields.name", "-").charCodeAt(0) - _.get(b, "fields.name", "-").charCodeAt(0)
      );

      engineDropdown = (
        <Dropdown
          value={engineFilter}
          placeholder="Engine"
          onChange={(value) => changeEngineFilter(value)}
          options={[
            {
              text: "Unity",
              value: "Unity",
            },
            {
              text: "TouchDesigner",
              value: "TouchDesigner",
            },
          ]}
        />
      );

      interactionDropdown = (
        <Dropdown
          value={interactionFilter}
          placeholder="Interaction"
          onChange={(value) => changeInteractionFilter(value)}
          options={interactions.map((interaction) => ({
            text: interaction,
            value: interaction,
          }))}
        />
      );

      technologyDropdown = (
        <Dropdown
          value={technologyFilter}
          placeholder="Technology"
          onChange={(value) => changeTechnologyFilter(value)}
          options={technologies.map((technology) => ({
            value: technology.id,
            text: _.get(technology, "fields.name", ""),
          }))}
        />
      );
    }
    projectBlocks = filteredProjects.map((project, i) => {
      let fields = project.fields;
      return (
        <PlayablePreview
          key={`project-${i}`}
          size="L"
          navigationData={{ projectID: project.id }}
          thumbnail={_.get(fields, "thumbnail[0].url", placeholderImage)}
          title={_.get(fields, "name", "")}
          isIncomplete={fields.status && fields.status !== "curated"}
          type="project"
        />
      );
    });
  }

  let searchbar = null;
  if (searchbarActivated) {
    searchbar = (
      <div className={classes.Searchbar}>
        <img src={iconSearch} alt="Searchbar icon" />
        <input
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => changeSearch(e.target.value.toLowerCase())}
        />
      </div>
    );
  }

  return (
    <div>
      <div className={classes.SearchActions}>
        {searchbar}
        {engineDropdown}
        {technologyDropdown}
        {interactionDropdown}
      </div>
      {!isHomepage && (
        <div className={classes.Filters}>
          {tags.filter(tag => tag.fields.uiIconKey).map((tag) => (
            <TagFilter
              key={tag.id}
              tag={tag}
              onClick={() => changeTagFilter(tagFilter === tag.id ? "" : tag.id)}
              isActive={tagFilter === tag.id}
            />
          ))}
        </div>
      )}
      <div className={classes.PageHeaderBar}>
        <div className={classes.PageTitle}>{isHomepage ? "Latest Projects" : "Projects"}</div>
        {!isHomepage && (
          <button
            className={classes.ToggleCuratedButton}
            onClick={() => changeShowIncompleteProjects(!showIncompleteProjects)}
          >
            <img
              src={showIncompleteProjects ? iconIncompleteHide : iconIncompleteShow}
              className={classes.ToggleCuratedIcon}
            />
            {showIncompleteProjects ? "Hide Incomplete projects" : "Show Incomplete Projects"}
          </button>
        )}
      </div>
      <div className={classes.Projects}>{projectBlocks}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    tags: state.airtableReducer.tags,
    projects: state.airtableReducer.projects,
    technologies: state.airtableReducer.technologies,
  };
};

export default connect(mapStateToProps, null)(Projects);
