import React, { useState, useEffect, useRef } from "react";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import { useLocation } from "@reach/router";
import _ from "lodash";
import SidebarPlaylist from "../../components/SidebarPlaylist/SidebarPlaylist";
import { navigateToPage } from "../../utils/utils";
import playIcon from "shared/icons/play.svg";
import stopButtonIcon from "shared/icons/stop_button.svg";
import placeholderImage from "shared/icons/placeholder.svg";

import iconEnterBlockArrow from "shared/icons/enter_block_arrow.svg";
import iconTechnology from "shared/icons/technology.svg";
import iconArrowNotes from "shared/icons/arrow_notes.svg";
import iconWarningSignPresets from "shared/icons/warning_sign_presets.svg";

import classes from "./Proto.module.sass";
import PlayablePreview from "../../components/PlayablePreview/PlayablePreview";
import Media from "../../components/Media/Media";
import GalleryTab from "../../components/MediaGallery/GalleryTab";
import Software from "components/ProtoOrProject/Software";
import { Collapse } from "antd";

/**
 * IMPORTANT
 *
 * Presets are loaded dynamically when the tablet send a current proto on MQTT topic demoRoom/common/state/proto
 * The presets are then immediately sent by the ProtoFramework to the tablet on demoRoom/common/state/protoPresets (see mqttActions.js file)
 *
 */

const Proto = ({
  changeNavigationPage,
  protos,
  presets,
  currentPreset,
  technologies,
  currentDemo,
  getItemByID,
  sendMQTTCurrentMedia,
  sendMQTTCurrentProject,
  sendMQTTCurrentProto,
  sendMQTTCurrentNavigation,
  sendMQTTCurrentDemo,
  currentProto,
  sendMQTTCurrentPreset,
  protoID,
  playlistID,
  demoID,
  playlistItemID,
}) => {
  const location = useLocation();
  const refLeftContent = useRef();

  useEffect(() => {
    changeNavigationPage({ protoID, playlistID, playlistItemID });
    console.log(protoID, playlistID, playlistItemID);
  }, [protoID, playlistID, playlistItemID]);

  useEffect(() => {
    if (!refLeftContent.current || !location.pathname) return;
    refLeftContent.current.scrollTo(0, 0);
  }, [location?.pathname]);

  //Load proto with protoID found in URL
  const [proto, changeProto] = useState(null);
  useEffect(() => {
    const activeProto = protos.find((p) => p.id === protoID);
    changeProto(activeProto);
    if (currentProto && currentProto.id === activeProto.id) {
      changeProtoLoadedState(true);
    } else {
      changeProtoLoadedState(false);
    }
    return () => {
      changeProto(null);
    };
  }, [currentProto, protos, protoID]);

  const [tab, changeTab] = useState("infos");
  const [protoLoaded, changeProtoLoadedState] = useState(false);

  const launchTechnology = (technology) => {
    let protoID = _.get(technology, "fields.showcaseProto[0]", "");
    if (protoID) {
      navigateToPage({ protoID });
    }
  };

  const startCurrentProto = (proto) => {
    sendMQTTCurrentProject(null, {});
    sendMQTTCurrentMedia(null, {});
    if (proto === null) {
      sendMQTTCurrentProto(null, {});
      sendMQTTCurrentMedia(null, {});
      changeProtoLoadedState(false);
      sendMQTTCurrentNavigation({});
    } else {
      if (demoID && (!currentDemo || (currentDemo && currentDemo.id !== demoID))) {
        let demo = getItemByID("demos", demoID);
        sendMQTTCurrentDemo(demo);
      }
      //sending also the ids for navigation back to proto
      sendMQTTCurrentProto(proto);
      sendMQTTCurrentNavigation({ playlistID, playlistItemID, protoID, demoID });
      setTimeout(() => {
        changeProtoLoadedState(true);
      }, 2000);
    }
  };

  const activatePreset = (preset) => {
    sendMQTTCurrentPreset(preset);
  };

  let sidebarPlaylist = null;
  let content = <div>No proto with this ID found !</div>;
  if (proto) {
    let fields = proto.fields;
    let currentTab = null;
    let currentPlaylist = playlistID ? getItemByID("playlists", playlistID) : null;
    let presetBlocks = (
      <div className={classes.NotLoadedMessageBlock}>
        <img
          className={classes.WarningIcon}
          src={iconWarningSignPresets}
          alt="warning sign, you need to press play to load presets"
        />
        <div className={classes.NotLoadedMessage}>PRESS PLAY TO LOAD PRESETS</div>
      </div>
    );
    if (presets && protoLoaded && currentProto && currentProto.id === protoID) {
      presetBlocks = (
        <div className={classes.Presets}>
          {presets.map((preset, i) => {
            let activeClass = null;
            if (!currentPreset && i === 0) {
              activeClass = classes.Active;
            }
            if (currentPreset && currentPreset === preset) {
              activeClass = classes.Active;
            }
            return (
              <div
                key={`preset-${i}`}
                className={classes.Preset + " " + activeClass}
                onClick={() => activatePreset(preset)}
              >
                {activeClass ? null : (
                  <div className={classes.Image}>
                    <img alt={"Play Icon"} src={iconEnterBlockArrow} />
                  </div>
                )}
                <div className={classes.PresetInfos}>
                  <div className={classes.Number}>{i.toString().padStart(3, "0")}</div>
                  <div className={classes.Title}>{preset}</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    if (currentPlaylist) {
      sidebarPlaylist = (
        <div className={classes.RightContent}>
          <SidebarPlaylist playlist={currentPlaylist} protoID={protoID} playlistID={playlistID} demoID={demoID} />
        </div>
      );
    } else {
      sidebarPlaylist = null;
    }

    let associatedTechnologies = null;
    let protoTechnologies = _.get(fields, "technologies", []);
    if (technologies && technologies.length && protoTechnologies && protoTechnologies.length) {
      let technologies = protoTechnologies.map((t) => getItemByID("technologies", t));
      associatedTechnologies = technologies.map((technology, i) => {
        let iconEnterTech = null;
        if (_.get(technology, "fields.showcaseProto[0]", "")) {
          iconEnterTech = (
            <div className={classes.IconEnterBlock}>
              <img src={iconEnterBlockArrow} alt="Enter technology" />
            </div>
          );
        }
        return (
          <div className={classes.Technology} key={`tech-${i}`} onClick={() => launchTechnology(technology)}>
            <div className={classes.IconTechnology}>
              <img src={iconTechnology} alt="Enter technology" />
            </div>
            {iconEnterTech}
            {/* <div className={classes.ButtonInfo}><div>i</div></div> */}
            <div className={classes.Title}>{_.get(technology, "fields.name", "no name")}</div>
          </div>
        );
      });
    }

    if (tab === "infos") {
      const relatedProtosData = _.get(fields, "relatedPrototypes", []).map((rp) => getItemByID("protos", rp));
      let relatedProtos = null;
      relatedProtos = (
        <div className={classes.Row4}>
          <div className={classes.RelatedProtos}>
            {relatedProtosData.map((proto, i) => {
              // If a relatedPrototype refers to a prototype with status "willNotFix",
              // it will be absent from the list of protos so we must skip protos them
              if (!proto) return null;

              let fields = proto.fields;
              let navigationData;
              if (!demoID && !playlistID) {
                navigationData = { protoID: proto.id };
              } else if (!demoID && playlistID) {
                navigationData = { protoID: proto.id, playlistID };
              } else {
                navigationData = { protoID: proto.id, playlistID, demoID };
              }
              return (
                <PlayablePreview
                  key={`proto-${i}`}
                  navigationData={navigationData}
                  isIncomplete={fields.status && fields.status !== "curated"}
                  thumbnail={_.get(fields, "thumbnail[0].url", placeholderImage)}
                  title={_.get(fields, "name", "")}
                />
              );
            })}
          </div>
        </div>
      );

      currentTab = (
        <div className={classes.Infos}>
          <div className={classes.Row2}>
            <div className={classes.NotesContainer}>
              <Collapse defaultActiveKey={["notes"]}>
                {_.get(fields, "howTo", "") && (
                  <Collapse.Panel header="How To" key="howTo">
                    <div className={classes.Notes}>{_.get(fields, "howTo", "")}</div>
                  </Collapse.Panel>
                )}
                {_.get(fields, "notes", "") && (
                  <Collapse.Panel header="Notes" key="notes">
                    <div className={classes.Notes}>
                      {_.get(fields, "notes", "")
                        .split("**")
                        .slice(1)
                        .map((line, i) => {
                          return (
                            <div key={`line-${i}`}>
                              <img src={iconArrowNotes} alt="arrow to identify statement" /> {line}
                              <br />
                              <br />
                            </div>
                          );
                        })}
                    </div>
                  </Collapse.Panel>
                )}
                {relatedProtosData.length && (
                  <Collapse.Panel header="Similar to" key="related">
                    {relatedProtos}
                  </Collapse.Panel>
                )}
              </Collapse>
            </div>
            <div className={classes.TechnologiesAndSoftwares}>
              <div className={classes.PresetsContainerWrapper}>
                <div className={classes.PresetsContainer}>
                  <div className={classes.ContainerTitle}>Presets</div>
                  {presetBlocks}
                </div>
              </div>
              <div className={classes.TechnologiesContainer}>
                <div className={classes.ContainerTitle}>Technology</div>
                <div className={classes.Technologies}>{associatedTechnologies}</div>
              </div>
              <Software engine={_.get(fields, "engine", "No engine specified")} />
            </div>
          </div>
        </div>
      );
    } else if (tab === "gallery") {
      currentTab = <GalleryTab item={proto} />;
    }

    let playButton = (
      <div className={classes.PlayButton} onClick={() => startCurrentProto(proto)}>
        <img alt={"Play Icon"} src={playIcon} />
      </div>
    );
    if (currentProto && currentProto.id === protoID) {
      playButton = (
        <div className={classes.StopButton} onClick={() => startCurrentProto(null)}>
          <img alt={"Stop Icon"} src={stopButtonIcon} />
        </div>
      );
    }

    content = (
      <>
        <div
          ref={refLeftContent}
          className={classes.LeftContent + " " + (sidebarPlaylist === null ? classes.FullWidth : "")}
        >
          <div className={classes.ContentHeader}>
            {playButton}
            <div className={classes.PreviewImageContainer}>
              <img
                className={classes.PreviewImage}
                alt="Preview of proto"
                src={_.get(fields, "thumbnail[0].url", placeholderImage)}
              />
            </div>
            <div className={classes.HeaderInfos}>
              <div className={classes.Title}>{_.get(fields, "name", "No Name")}</div>
              <div className={classes.Tags}>
                {_.get(fields, "tagNames", [])
                  .map((t) => "#" + t + " ")
                  .join("")}
              </div>
              <div className={classes.CreatedContainer}>
                {/* <div className={classes.CreatedImage}></div> */}
                <div className={classes.CreatedInfo}>
                  <div className={classes.CreatedInfoText}>Created by {_.get(fields, "createdBy", "")}</div>
                  <div className={classes.CreatedInfoDate}>{new Date(_.get(fields, "created", "")).toDateString()}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.Content}>
            <div className={classes.Tabs}>
              <div
                className={classes.Tab + " " + (tab === "infos" ? classes.Active : "")}
                onClick={() => changeTab("infos")}
              >
                <div>Infos</div>
              </div>
              <div
                className={classes.Tab + " " + (tab === "gallery" ? classes.Active : "")}
                onClick={() => changeTab("gallery")}
              >
                <div>Gallery</div>
              </div>
            </div>
            {currentTab}
          </div>
        </div>
        {sidebarPlaylist}
      </>
    );
  }

  return <div className={classes.ProtoContainer}>{content}</div>;
};

const mapStateToProps = (state) => {
  return {
    currentDemo: state.mqttReducer.currentDemo,
    protos: state.airtableReducer.protos,
    presets: state.mqttReducer.presets,
    currentProto: state.mqttReducer.currentProto,
    currentPreset: state.mqttReducer.currentPreset,
    technologies: state.airtableReducer.technologies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeNavigationPage: (ids) => {
      return dispatch(actions.changeNavigationPage(ids));
    },
    getItemByID: (model, id) => {
      return dispatch(actions.getItemByID(model, id));
    },
    sendMQTTCurrentProto: (proto) => {
      return dispatch(actions.sendMQTTCurrentProto(proto));
    },
    sendMQTTCurrentProject: (project) => {
      return dispatch(actions.sendMQTTCurrentProject(project));
    },
    sendMQTTCurrentNavigation: (navigation) => {
      return dispatch(actions.sendMQTTCurrentNavigation(navigation));
    },
    sendMQTTCurrentPreset: (preset) => {
      return dispatch(actions.sendMQTTCurrentPreset(preset));
    },
    sendMQTTCurrentMedia: (media) => {
      return dispatch(actions.sendMQTTCurrentMedia(media));
    },
    sendMQTTCurrentDemo: (demo) => {
      return dispatch(actions.sendMQTTCurrentDemo(demo));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Proto);
