import React, { useEffect, useState, useContext } from "react";
import { Router } from "@reach/router";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "@reach/router";

import * as actions from "./store/actions/index";

import classes from "./App.module.sass";

import Layout from "./hoc/Layout/Layout";
import Demo from "./containers/Demo/Demo";
import Demos from "./containers/Demos/Demos";
import Playlist from "./containers/Playlist/Playlist";
import Playlists from "./containers/Playlists/Playlists";
import Proto from "./containers/Proto/Proto";
import Protos from "./containers/Protos/Protos";
import Project from "./containers/Project/Project";
import Projects from "./containers/Projects/Projects";
import Medias from "./containers/Medias/Medias";
import PlaylistItem from "./containers/PlaylistItem/PlaylistItem";
import WarningNotice from "./components/WarningNotice/WarningNotice";
import Login from "./hoc/Layout/Login";
import { GoogleAuthContext } from "./utils/GoogleAuthProvider";

function ScrollToTop({ children }) {
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname) return;
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return children;
}

const App = ({ initApp, currentDemo, currentProto }) => {
  const dispatch = useDispatch();
  const { loading: authLoading, error, isSignedIn, gapi } = useContext(GoogleAuthContext);

  useEffect(() => {
    dispatch(initApp);
  }, []);

  if (process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID && error) return "Google Auth Error";

  const hasDemos = process.env.REACT_APP_OFFICE_LOCATION === "Montreal";

  let Homepage = () => (
    <div className={classes.Homepage}>
      {hasDemos && <Demos isHomepage />}
      <Playlists isHomepage />
      <Protos isHomepage path="/protos" />
    </div>
  );

  /**
   *
   * Reach router is really simple to use.
   *
   * Just specify all the possible paths for your app below.
   * All the pages in my react app are called containers and
   * are surrounded by the layout (higher order compenent hoc)
   *
   * The page params are automatically added to the props of the container ! (ex: Demos container will have demoID props)
   *
   */
  let page = (
    <Router>
      <ScrollToTop path="/">
        <Homepage path="/" />
        <Demos searchbarActivated={true} path="/demos" />
        <Demo path="/demos/:demoID" />
        <Playlists searchbarActivated={true} path="/playlists" />
        <Playlist path="/playlists/:playlistID" />
        <Protos searchbarActivated={true} path="/protos" />
        <Proto path="/protos/:protoID" />

        <Projects searchbarActivated={true} path="/projects" />
        <Project path="/projects/:projectID" />

        <PlaylistItem path="/playlists/:playlistID/playlistItem/:playlistItemID" />
        <PlaylistItem path="/demos/:demoID/:playlistID/playlistItem/:playlistItemID" />

        <Medias searchbarActivated={true} path="/medias" />
      </ScrollToTop>
    </Router>
  );

  let warningNotice = null;
  //Warning sign only appear if current demo/proto at login
  if (1 == 1) {
    warningNotice = <WarningNotice />;
  }

  if (authLoading) {
    return (
      <div className={classes.Container}>
        <div className={classes.Content} style={{ height: "100vh" }}>
          Loading...
        </div>
      </div>
    );
  }

  if (process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID) {
    if (!isSignedIn) {
      return <Login gapi={gapi} />;
    }
  }

  return (
    <>
      {warningNotice}
      <Layout>
        <div className={classes.Container}>
          <div className={classes.Content}>{page}</div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentProto: state.mqttReducer.currentProto,
    currentDemo: state.mqttReducer.currentDemo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    initApp: () => {
      dispatch(actions.initMQTTClient());
      dispatch(actions.sendMQTTRefreshCache());
      dispatch(actions.fetchDemos());
      dispatch(actions.fetchSessions());
      dispatch(actions.fetchProtos());
      dispatch(actions.fetchPlaylists());
      dispatch(actions.fetchPlaylistItems());
      dispatch(actions.fetchMedias());
      dispatch(actions.fetchVisitors());
      dispatch(actions.fetchDemonstrators());
      dispatch(actions.fetchResponsibles());
      dispatch(actions.fetchFeedbacks());
      dispatch(actions.fetchTechnologies());
      dispatch(actions.fetchTags());
      dispatch(actions.fetchProjects());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
