import { combineReducers, applyMiddleware, createStore, compose } from 'redux'
import airtableReducer from './store/reducers/airtableReducer'
import mqttReducer from './store/reducers/mqttReducer'
import applicationReducer from './store/reducers/applicationReducer'
import thunk from 'redux-thunk'

/**
 * REDUX OUTSIDE REACT ?
 * 
 * Why putting the redux store outside in its own file ?
 * By doing so, you enable redux to be completly independant from React
 * It becomes your decision to import the redux state anywhere you need and manipulates it.
 * 
 * I decided to do so after creating the breadcrumb system with the function navigateToPage() in utils
 * My utils function are completly out of React and can still call redux and manage my state.
 * Personally, I found it really interesting to use Redux like this.
 * I prefer to keep pieces of my code more independent when its possible, making it easier to manage and understand.
 * 
 */

const rootReducer = combineReducers({
  airtableReducer,
  mqttReducer,
  applicationReducer
})

const logger = store => {
  return next => {
    return action => {
      const result = next(action)
      return result
    }
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(logger, thunk))
)

export default store
