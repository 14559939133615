import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { navigateToPage } from '../../utils/utils'
import dayjs from 'dayjs';

import iconSearch from 'shared/icons/search.svg'
import iconEnterBlockArrow from 'shared/icons/enter_block_arrow.svg'
import iconPlusSymbol from 'shared/icons/plus_symbol.svg'

import classes from './Demos.module.sass'

const Demos = ({ demos, sessions, searchbarActivated, isHomepage }) => {
  const [search, changeSearch] = useState('')

  const demoVisitorIds = {};
  demos.forEach(demo => {
    demoVisitorIds[demo.id] = sessions
      .filter((session) => session.fields.visitId && session.fields.visitId.indexOf(demo.id) >= 0)
      .map((session) => {
        return  session.fields.visitorId && session.fields.visitorId.length && session.fields.visitorId[0];
      })
  })

  //This can be converted to a component
  const generateBlocksForDemos = (demosToGenerateBlocks, title) => {
    let blocks = demosToGenerateBlocks.sort((a,b) => new Date(_.get(b.fields, 'demoStartTime', 0)) - new Date(_.get(a.fields, 'demoStartTime', 0))).map((demo, i) => {
      let fields = demo.fields
      return (
        <div key={`demo-${i}`} onClick={() => navigateToPage({ demoID: demo.id })} className={classes.Demo}>
          <div className={classes.DemoInfos}>
            <div className={classes.IconEnterBlock}>
              <img src={iconEnterBlockArrow} alt="Enter demo" />
            </div>
            <div className={classes.Title}>{_.get(fields, "visitName", "")}</div>
            <div className={classes.KeyInfo}>
              <div className={classes.Info}>{new Date(_.get(fields, "demoStartTime", 0)).toDateString()}</div>
            </div>
            <div className={classes.KeyInfo}>
              <div className={classes.Info}>
                {dayjs(_.get(fields, "demoStartTime", 0)).format("h:mm a") +
                  " - " +
                  dayjs(_.get(fields, "demoEndTime", 0)).format("h:mm a")}
              </div>
            </div>
          </div>
          <div className={classes.Visitors}>{demoVisitorIds[demo.id].length} Visitors</div>
        </div>
      );
    })
    return (
      <>
        <div className={classes.PageTitle}>{title}</div>
        <div className={classes.Demos}>
          {blocks.length ? blocks : <div className={classes.NoDemosToday}>No demos</div>}
        </div>
      </>
    )
  }

  let demoBlocks = null
  if(demos) {
    if(search != '') {
      demos = demos.filter(demo => _.get(demo.fields, 'visitName', '').toLowerCase().includes(search))
    }

    let now = new Date()
    let startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    let endOfDay = new Date((startOfDay).getTime() + 86399999)

    let demosToday = demos.filter(demo => new Date(_.get(demo.fields, 'demoStartTime', 0)) > startOfDay && new Date(_.get(demo.fields, 'demoStartTime', 0)) < endOfDay)
    let demosPast = demos.filter(demo => new Date(_.get(demo.fields, 'demoStartTime', 0)) < startOfDay)
    let demosNext = demos.filter(demo => new Date(_.get(demo.fields, 'demoStartTime', 0)) > endOfDay)
    let demoBlocksToday = generateBlocksForDemos(demosToday, "TODAY'S DEMOS")
    let demoBlocksPast = generateBlocksForDemos(demosPast, "PAST DEMOS")
    let demoBlocksNext = generateBlocksForDemos(demosNext, "NEXT DEMOS")

    if(isHomepage) {
      demoBlocks = (
        <>
          {demoBlocksToday}
          {demoBlocksNext}
        </>
      )
    } else {
      demoBlocks = (
        <>
          {demoBlocksToday}
          {demoBlocksNext}
          {demoBlocksPast}
        </>
      )
    }
  }

  let searchbar = null
  if(searchbarActivated) {
    searchbar = (
      <div className={classes.Searchbar}>
        <img src={iconSearch} alt="Searchbar icon" />
        <input type="text" placeholder="Search" value={search} onChange={(e) => changeSearch(e.target.value.toLowerCase())} />
      </div>
    )
  }

  return (
    <div className={classes.DemosContainer}>
      {searchbar}
      <div className={classes.ButtonNewDemo} onClick={() => window.open(process.env.REACT_APP_RESERVATION_URL,'_blank')}>
        <img src={iconPlusSymbol} alt="Add new demo"/>
        <div className={classes.ButtonNewDemoText}>NEW DEMO</div>
      </div>
      {demoBlocks}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    demos: state.airtableReducer.demos,
    sessions: state.airtableReducer.sessions
  }
}

export default connect(mapStateToProps, null)(Demos)
