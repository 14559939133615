import * as actionTypes from './actionTypes'
import _ from 'lodash'
import mqtt from 'mqtt'
import mqttMatch from 'mqtt-match'
import store from '../../store-redux'

import * as airtableActions from './airtableActions'

const MQTT_TOPIC_PUBLISH = {
  CURRENT_DEMO: `demoRoom/common/state/demo`,
  CURRENT_PROTO: `demoRoom/common/state/proto`,
  CURRENT_PROJECT: `demoRoom/common/state/project`,
  CURRENT_NAVIGATION: `demoRoom/common/state/navigation`,
  CURRENT_MEDIA: `demoRoom/common/state/media`,
  CURRENT_PRESET: `demoRoom/common/state/protoPreset`,
  SCRUB_MEDIA: `demoRoom/common/event/scrubMedia`,
  REFRESH_CACHE: `demoRoom/common/event/clearMiddlewareCache`,

  // TODO: protoFramework never sends this, might be obsolete
  VOLUME: `demoRoom/common/state/volume`
}

const MQTT_TOPIC_SUBSCRIBED = [
  "connectedFactory/middleware/datacache",

  // TODO: protoFramework never sends this, might be obsolete
  `demoRoom/common/state/volume`,

  `demoRoom/connectedFactory/state/guestsCheckedInIds`,
  `demoRoom/common/state/demo`,
  `demoRoom/common/state/proto`,
  `demoRoom/common/state/project`,
  `demoRoom/common/state/navigation`,
  `demoRoom/common/state/media`,
  `demoRoom/common/state/protoPresets`,
  `demoRoom/common/state/protoPreset`,
]

function createMQTTClient(mqttClient) {
  return {
    type: actionTypes.CREATE_MQTT_CLIENT,
    receivedAt: Date.now(),
    mqttClient
  }
}

/**
 * MQTT client implementation
 *
 * This approach to initiate the MQTT client all in redux is the one that worked
 * the best after multiple try in different projects.
 *
 * The client is initiated with the ENV variable declared in the .env,
 * then we put the client inside the mqttReducer state.
 *
 * Adding redux-thunk to the equation, we can now easily get the client inside all of our actions.
 * Using the function getState().mqttReducer.client, we can simply dispatch our actions from react.
 *
 * The MQTT logic will always be in this file ! Including how we handle what we publish and what happens when
 * we received a message on a topic that we are subscribed to.
 *
 * By completly separating MQTT and the React code, I think everything becomes more simple since we create an easy go-to
 * situation when it comes to MQTT in our code.
 *
 */
export function initMQTTClient() {
  return function(dispatch, getState) {
    let mqttClient = mqtt.connect(
      `${process.env.REACT_APP_MQTT_PROTOCOL}://${process.env.REACT_APP_MQTT_HOST}:${process.env.REACT_APP_MQTT_PORT}`
    );
    mqttClient.subscribe(MQTT_TOPIC_SUBSCRIBED)
    mqttClient.on('connect', () => onConnect(dispatch))
    mqttClient.on('error', (error) => onError(dispatch, error))
    mqttClient.on('message', (topic, payload) => onMessageArrived(dispatch, topic, payload))
    mqttClient.on('offline', () => onOffline(dispatch))
    mqttClient.on('close', () => onClose(dispatch))
    mqttClient.on('end', () => onConnectionLost(dispatch))
    dispatch(createMQTTClient(mqttClient))
  }
}

function changeMQTTClientState(state) {
  return {
    type: actionTypes.CHANGE_MQTT_CLIENT_STATE,
    receivedAt: Date.now(),
    state
  }
}

function onConnect(dispatch) {
  dispatch(changeMQTTClientState(true))
}

function onClose(dispatch) {
  dispatch(changeMQTTClientState(false))
}

function onOffline(dispatch) {
  dispatch(changeMQTTClientState(false))
}

function onConnectionLost(dispatch) {
  dispatch(changeMQTTClientState(false))
}

function errorMQTTClient(error) {
  return {
    type: actionTypes.ERROR_MQTT_CLIENT,
    receivedAt: Date.now(),
    error
  }
}

function onError(dispatch, error) {
  dispatch(errorMQTTClient(error))
}

function sendMQTTGeneric(type, payload) {
  return {
    type,
    receivedAt: Date.now(),
    payload
  }
}

export function sendMQTTRefreshCache() {
  return function (dispatch, getState) {
    let mqttClient = getState().mqttReducer.mqttClient
    let payload = JSON.stringify({})
    mqttClient.publish(MQTT_TOPIC_PUBLISH.REFRESH_CACHE, payload)
    dispatch(sendMQTTGeneric(actionTypes.MQTT_REFRESH_CACHE, payload))
  }
}

export function sendMQTTScrubMedia(scrubValue) {
  return function (dispatch, getState) {
    let mqttClient = getState().mqttReducer.mqttClient
    let payload = JSON.stringify({
      scrubMedia: scrubValue
    })
    mqttClient.publish(MQTT_TOPIC_PUBLISH.SCRUB_MEDIA, payload)
    dispatch(sendMQTTGeneric(actionTypes.MQTT_SCRUB_MEDIA, payload))
  }
}

function sendMQTTCurrentDemoRequest(payload, demo) {
  return {
    type: actionTypes.MQTT_CURRENT_DEMO,
    receivedAt: Date.now(),
    payload,
    demo
  }
}

export function sendMQTTCurrentDemo(demo) {
  return function (dispatch, getState) {
    let mqttClient = getState().mqttReducer.mqttClient
    let payload = {
      airtableID:  _.get(demo, 'id', null),
      visitID: _.get(demo, "fields['visitID']", null),
      visitName: _.get(demo, "fields['visitName']", null),
      language: _.get(demo, "fields['language']", null),
      Date: _.get(demo, "fields['date']", null),
      Time: _.get(demo, "fields['time']", null),
    }
    mqttClient.publish(MQTT_TOPIC_PUBLISH.CURRENT_DEMO, JSON.stringify(payload), {retain: true})
    dispatch(sendMQTTCurrentDemoRequest(payload, demo))
  }
}

function sendMQTTCurrentGuestsCheckedInIdsRequest(payload) {
  return {
    type: actionTypes.MQTT_CURRENT_GUESTS_CHECKED_IN_IDS,
    receivedAt: Date.now(),
    payload,
  };
}

function sendMQTTCurrentProjectRequest(payload, project) {
  return {
    type: actionTypes.MQTT_CURRENT_PROJECT,
    receivedAt: Date.now(),
    payload,
    project,
  };
}

export function sendMQTTCurrentProject(project) {
  return function (dispatch, getState) {
    let mqttClient = getState().mqttReducer.mqttClient
    let payload = {
      airtableID: _.get(project, 'id', null),
      path: _.get(project, 'fields.relativePath', null),
      title: _.get(project, 'fields.name', null),
      startDemo: false
    }
    if(!project) {
      payload = {}
    }
    mqttClient.publish(MQTT_TOPIC_PUBLISH.CURRENT_PROJECT, JSON.stringify(payload), {retain: true})
    dispatch(sendMQTTCurrentProjectRequest(payload, project))
  }
}

function sendMQTTCurrentProtoRequest(payload, proto) {
  return {
    type: actionTypes.MQTT_CURRENT_PROTO,
    receivedAt: Date.now(),
    payload,
    proto,
  };
}

export function sendMQTTCurrentProto(proto) {
  return function (dispatch, getState) {
    let mqttClient = getState().mqttReducer.mqttClient
    let payload = {
      airtableID: _.get(proto, 'id', null),
      uuid: _.get(proto, 'fields.uuid', null),
      title: _.get(proto, 'fields.name', null),
      startDemo: false
    }
    if(!proto) {
      payload = {}
    }
    mqttClient.publish(MQTT_TOPIC_PUBLISH.CURRENT_PROTO, JSON.stringify(payload), {retain: true})
    dispatch(sendMQTTCurrentProtoRequest(payload, proto))
  }
}

function sendMQTTCurrentNavigationRequest(payload) {
  return {
    type: actionTypes.MQTT_CURRENT_NAVIGATION,
    receivedAt: Date.now(),
    payload
  };
}

export function sendMQTTCurrentNavigation(payload) {
  return function (dispatch, getState) {
    let mqttClient = getState().mqttReducer.mqttClient;
    mqttClient.publish(MQTT_TOPIC_PUBLISH.CURRENT_NAVIGATION, JSON.stringify(payload), { retain: true });
    dispatch(sendMQTTCurrentNavigationRequest(payload));
  };
}

function sendMQTTCurrentPresetRequest(preset, payload) {
  return {
    type: actionTypes.MQTT_CURRENT_PRESET,
    receivedAt: Date.now(),
    preset,
    payload
  }
}

export function sendMQTTCurrentPreset(preset) {
  return function (dispatch, getState) {
    let mqttClient = getState().mqttReducer.mqttClient
    let payload = { currentPreset: preset }
    mqttClient.publish(MQTT_TOPIC_PUBLISH.CURRENT_PRESET, JSON.stringify(payload), {retain: true})
    dispatch(sendMQTTCurrentPresetRequest(preset, payload))
  }
}

function sendMQTTCurrentVolumeRequest(payload, volume) {
  return {
    type: actionTypes.MQTT_VOLUME,
    receivedAt: Date.now(),
    payload,
    volume
  }
}

export function sendMQTTCurrentVolume(volume) {
  return function(dispatch, getState) {
    let mqttClient = getState().mqttReducer.mqttClient
    let payload = {
      volume: volume
    }
    mqttClient.publish(MQTT_TOPIC_PUBLISH.VOLUME, JSON.stringify(payload), {retain: true})
    dispatch(sendMQTTCurrentVolumeRequest(payload, volume))
  }
}

function sendMQTTCurrentMediaRequest(payload, media) {
  return {
    type: actionTypes.MQTT_CURRENT_MEDIA,
    receivedAt: Date.now(),
    payload,
    media
  }
}

export function sendMQTTCurrentMedia(media) {
  return function (dispatch, getState) {
    let mqttClient = getState().mqttReducer.mqttClient
    let payload = {
      airtableID:  _.get(media, 'id', null),
      path: _.get(media, "fields['relativePath']", ""),
      type: _.get(media, "fields['type']", "").toLowerCase()
    }
    mqttClient.publish(MQTT_TOPIC_PUBLISH.CURRENT_MEDIA, JSON.stringify(payload), {retain: true})
    dispatch(sendMQTTCurrentMediaRequest(payload, media))
  }
}

function handleMessageCurrentProtoPresets(payload) {
  return {
    type: actionTypes.MQTT_CURRENT_PROTO_PRESETS,
    receivedAt: Date.now(),
    payload
  }
}

function handleMessageDisplaySensorsInfos(payload) {
  return {
    type: actionTypes.MQTT_DISPLAY_SENSORS_INFOS,
    receivedAt: Date.now(),
    payload
  }
}

/**
 * A function to rapidly get a specific item from our cache
 * By specifying the model and the airtable id you can easily get the item
 * referenced inside a field of a prototypes. (or any other airtable object)
 *
 * Since airtable does not allow to populate automatically the ids of one-to-multiple or one-to-one relationship,
 * we use this function in the frontend to get the full items linked to a model.
 *
 * @param model the model name as stated in the airtableReducer state
 * @param id airtable id
 */
function getItemByID(model, id) {
  const airtableState = store.getState().airtableReducer
  if(model in airtableState) {
    return airtableState[model].find(i => i.id === id)
  }
  return null
}


function onMessageArrived(dispatch, topic, payload) {
  payload = JSON.parse(payload)

  if (mqttMatch("demoRoom/common/state/protoPresets", topic)) {
    dispatch(handleMessageCurrentProtoPresets(payload))
  }

  else if (mqttMatch("demoRoom/common/state/media", topic)) {
    let media = getItemByID('medias', payload.airtableID)
    dispatch(sendMQTTCurrentMediaRequest(payload, media))
  }

  else if (mqttMatch("demoRoom/common/state/protoPreset", topic)) {
    let preset = payload.currentPreset
    dispatch(sendMQTTCurrentPresetRequest(preset, payload))
  }

  else if (mqttMatch("demoRoom/common/state/demo", topic)) {
    let demo = getItemByID('demos', payload.airtableID)
    dispatch(sendMQTTCurrentDemoRequest(payload, demo))
  }

  else if (mqttMatch("demoRoom/common/state/proto", topic)) {
    let proto = getItemByID('protos', payload.airtableID)
    dispatch(sendMQTTCurrentProtoRequest(payload, proto))
  }

  else if (mqttMatch("demoRoom/common/state/project", topic)) {
    let project = getItemByID('projects', payload.airtableID)
    dispatch(sendMQTTCurrentProjectRequest(payload, project))
  }

  else if (mqttMatch("demoRoom/common/state/navigation", topic)) {
    dispatch(sendMQTTCurrentNavigationRequest(payload))
  }

  // TODO: protoFramework never sends currentVolume, might be obsolete
  else if (mqttMatch("demoRoom/common/state/volume", topic)) {
    let volume = payload.volume
    dispatch(sendMQTTCurrentVolumeRequest(payload, volume))
  }

  else if (mqttMatch("demoRoom/connectedFactory/state/guestsCheckedInIds", topic)) {
    dispatch(sendMQTTCurrentGuestsCheckedInIdsRequest(payload))
  }

  else if (mqttMatch("demoRoom/common/event/clearMiddlewareCache", topic)) {
    const data = payload;

    const mapTableToAction = {
      Visits: airtableActions.fetchDemosSuccess,
      Sessions: airtableActions.fetchSessionsSuccess,
      Protos: airtableActions.fetchProtosSuccess,
      Projects: airtableActions.fetchProjectsSuccess,
      Playlists: airtableActions.fetchPlaylistsSuccess,
      PlaylistItems: airtableActions.fetchPlaylistItemsSuccess,
      Medias: airtableActions.fetchMediasSuccess,
      Visitors: airtableActions.fetchVisitorsSuccess,
      Demonstrators: airtableActions.fetchDemonstratorsSuccess,
      Responsibles: airtableActions.fetchResponsiblesSuccess,
      Feedbacks: airtableActions.fetchFeedbacksSuccess,
      Technologies: airtableActions.fetchTechnologiesSuccess,
      Tags: airtableActions.fetchTagsSuccess
    }

    // Loop over all the refreshed data and update the corresponding state if we have a handler for it
    // This makes sure we don't try to update data that hasn't been refreshed by the backend
    // It also makes sure we don't try to handle data from the backend that we don't have a handler for
    Object.keys(data).forEach(table => {
      if(mapTableToAction[table]) {
        dispatch(mapTableToAction[table](data[table]));
      }
    });
  }
}
