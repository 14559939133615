import React, { useState } from 'react'
import classes from './Layout.module.sass'
import SideDrawer from './SideDrawer/SideDrawer'
import Toolbar from './Toolbar/Toolbar'

const Layout = ({children}) => {
  const [sideDrawerOpen, toggleSideDrawer] = useState(false)

  return (
    <>
      <Toolbar
        changeState={() => toggleSideDrawer(!sideDrawerOpen)}
        drawerToggleClicked={() => toggleSideDrawer(!sideDrawerOpen)}
      />
      <SideDrawer
        open={sideDrawerOpen}
        closed={() => toggleSideDrawer(false)}
      />
      <div className={' ' + classes.Content}>
        {children}
      </div>
    </>
  )
}

export default Layout
