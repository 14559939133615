import React from "react";

import classes from "./GallerySection.module.sass";
import Media from "components/Media/Media";

export default function GallerySection({ title, items }) {
  return (
    <div className={classes.GallerySection}>
      <div className={classes.ContainerTitle}>{title}</div>
      <div className={classes.Grid}>
        {items.map((mediaItem) => (
          <Media key={`media-${mediaItem.id}`} media={mediaItem} />
        ))}
      </div>
    </div>
  );
}
