import axios from 'axios'

const instance = axios.create({
  // baseURL: 'https://api.airtable.com/v0/appKEaYcqyG0vt87s/',
  baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
  // headers: {
  //   'Content-Type': 'application/json',
  //   'Authorization': 'Bearer ' + process.env.REACT_APP_AIRTABLE_BEARER_TOKEN
  // }
})

export default instance
