import React, { useEffect, useState, createContext } from "react";
import useScript from "react-script-hook";

export const GoogleAuthContext = createContext({
  gapi: null,
  isSignedIn: false,
  loading: false,
  error: null,
});

function GoogleAuthProvider({ children }) {
  const [gapi, setGapi] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [scriptLoading, scriptError] = useScript({ src: "https://apis.google.com/js/platform.js" });

  const [authLoading, setAuthLoading] = useState(true);
  const [authError, setAuthError] = useState(false);

  // Initialize Google API
  useEffect(() => {
    if (scriptError || scriptLoading) return;
    const gapi = window.gapi;
    gapi.load("auth2", () => {
      // Configure API
      try {
      gapi.auth2
        .init({
          client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
        })
        .then(
          (auth2) => {
            setGapi(gapi);
            // Check SignedIn status
            setIsSignedIn(auth2.isSignedIn.get());
            auth2.isSignedIn.listen((isSignedIn) => {
              setIsSignedIn(isSignedIn);
            });

            setAuthLoading(false);
          },
          (gapiError) => {
            setAuthError(gapiError);
            setAuthLoading(false);
          }
        )
      } catch (err) {
        setAuthError(err.message);
      }
    });
  }, [scriptLoading, scriptError]);

  const loading = authLoading || scriptLoading;
  const error = authError || scriptError;

  return (
    <GoogleAuthContext.Provider value={{ loading, error, gapi, isSignedIn }}>{children}</GoogleAuthContext.Provider>
  );
}

export default GoogleAuthProvider;
