import React, { useEffect, useState } from "react";
import _, { first } from "lodash";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import playIcon from "shared/icons/play.svg";
import addVisitorIcon from "shared/icons/add_visitor.svg";
import stopButtonIcon from "shared/icons/stop_button.svg";
import dayjs from "dayjs";

import classes from "./Demo.module.sass";
import SidebarPlaylist from "../../components/SidebarPlaylist/SidebarPlaylist";
import { navigateToPage } from "../../utils/utils";

const Demo = ({
  changeNavigationPage,
  demoID,
  demos,
  sessions,
  visitors,
  getItemByID,
  sendMQTTCurrentDemo,
  sendMQTTCurrentPreset,
  sendMQTTCurrentProto,
  sendMQTTCurrentProject,
  currentDemo,
  currentGuestsCheckedInIds,
}) => {
  const [demo, changeDemo] = useState(null);
  const [visitorSessions, changeVisitorSessions] = useState(null);

  //Handle current demo change
  useEffect(() => {
    const currentDemo = demos.find((d) => d.id === demoID);
    if (currentDemo) {
      changeDemo(currentDemo);
    }
    return () => {
      changeDemo(null);
    };
  }, [demos, demoID]);

  useEffect(() => {
    changeNavigationPage({ demoID });
  }, [demoID]);

  //Handle current demo change
  useEffect(() => {
    if (!visitors || !visitors.length || !sessions || !sessions.length || !demoID) return;

    // Get VisitorIds from the Session that has the given visitId
    const sessionsInVisit = sessions
      .filter((session) => session.fields.VisitId && session.fields.VisitId.indexOf(demoID) >= 0)
      .map((session) => ({
        session,
        visitor: visitors.find((visitor) => session.fields.VisitorId && session.fields.VisitorId[0] === visitor.id),
      }))
      .map((session) => {
        return session;
      });

    // Filter visitors
    changeVisitorSessions(sessionsInVisit);

    return () => {
      changeVisitorSessions(null);
    };
  }, [demoID, sessions, visitors]);

  //Send launched demo informations and guests to the ProtoFramework
  const startCurrentDemo = () => {
    if (currentDemo && currentDemo.id === demoID) {
      sendMQTTCurrentDemo(null);
      //Reset proto MQTT state
      sendMQTTCurrentPreset(null);
      sendMQTTCurrentProto(null);
    } else {
      sendMQTTCurrentDemo(demo);
      //Reset proto MQTT state
      sendMQTTCurrentPreset(null);
      let currentPlaylist = getItemByID("playlists", _.get(demo.fields, "playlist[0]", ""));
      let playlistItemID = _.get(currentPlaylist.fields, `playlistItems[0]`);

      //Start proto at the same time of demo
      let firstItem = getItemByID("playlistItems", playlistItemID);
      const isProto = firstItem.fields?.proto;
      const isProject = firstItem.fields?.project;

      if (isProto) {
        const proto = getItemByID("protos", firstItem.fields.proto[0]);
        sendMQTTCurrentProto(proto);
      } else if (isProject) {
        const project = getItemByID("projects", firstItem.fields.project[0]);
        sendMQTTCurrentProject(project);
      }

      //Navigate to page
      navigateToPage({ playlistID: currentPlaylist.id, playlistItemID });
    }
  };

  let content = <div>No demo with this ID found !</div>;
  if (demo) {
    let fields = demo.fields;
    let currentPlaylist = getItemByID("playlists", _.get(fields, "playlist[0]", ""));
    let sidebarPlaylist = null;
    if (currentPlaylist) {
      sidebarPlaylist = (
        <>
          <SidebarPlaylist demoID={demoID} playlist={currentPlaylist} stayInPlaylist={true} />
        </>
      );
    }
    let playButton = (
      <div className={classes.PlayButton} onClick={() => startCurrentDemo()}>
        <img alt={"Play Icon"} src={playIcon} />
      </div>
    );
    if (currentDemo && currentDemo.id === demoID) {
      playButton = (
        <div className={classes.StopButton} onClick={() => startCurrentDemo(null)}>
          <img alt={"Stop Icon"} src={stopButtonIcon} />
        </div>
      );
    }

    // Quick hack to fix errors when data isn't loaded fast enough
    if (!visitorSessions) return "...";

    content = (
      <>
        <div className={classes.LeftContent}>
          <div className={classes.ContentHeader}>
            {playButton}
            <div className={classes.Title}>{_.get(fields, "visitName", "No Name")}</div>
            <div className={classes.CreatedContainer}>
              {/* <div className={classes.CreatedImage}></div> */}
              <div className={classes.CreatedInfo}>
                <div className={classes.CreatedInfoText}>Created by {_.get(fields, "ownerName", "")}</div>
                <div className={classes.CreatedInfoDate}>{new Date(fields.demoStartTime).toDateString()}</div>
              </div>
            </div>
          </div>
          <div className={classes.ContentInfo}>
            <div className={classes.VisitInfos}>
              <div className={classes.DateAndLanguage}>
                <div>{new Date(_.get(fields, "demoStartTime", 0)).toDateString()}</div>
                <div>
                  {dayjs(_.get(fields, "demoStartTime", 0)).format("h:mm a") +
                    " - " +
                    dayjs(_.get(fields, "demoEndTime", 0)).format("h:mm a")}
                </div>
                <div>{_.get(fields, "language", "")}</div>
              </div>
              <div className={classes.VisitGoals}>
                <div className={classes.VisitGoalsTitle}>VISIT GOALS</div>
                <div className={classes.VisitGoalsText}>
                  {_.get(
                    fields,
                    "visitGoals",
                    "Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit."
                  )}
                </div>
              </div>
            </div>
            <div className={classes.DemoInfos}>
              <div className={classes.DemoPresentatorContainer}>
                <div className={classes.DemoPresentatorTitle}>DEMO PRESENTATOR</div>
                <div className={classes.DemoPresentator}>
                  {/* <div className={classes.DemoPresentatorPicture}></div> */}
                  <div className={classes.DemoPresentatorName}>{_.get(fields, "demonstratorName", "")}</div>
                </div>
              </div>
              <div className={classes.DemoBriefContainer}>
                <div className={classes.DemoBriefTitle}>DEMO BRIEF</div>
                <div className={classes.DemoBrief}>
                  {_.get(
                    fields,
                    "demoBrief",
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className={classes.VisitorsTitle}>Visitors</div> */}
          <div className={classes.Visitors}>
            {visitorSessions.map((visitorSession, i) => {
              const takeawayFrame =
                visitorSession.session.fields.takeaway &&
                visitorSession.session.fields.takeaway.find(
                  (takeaway) => takeaway.filename === "welcomeStationFrame.jpg"
                );

              const isCheckedIn = typeof visitorSession.session.fields.checkinEndedAt !== "undefined";
              const isConnected = currentGuestsCheckedInIds.indexOf(visitorSession.session.id) >= 0;
              const status = (isConnected && "Connected") || (isCheckedIn && "Checked In") || "Invited";

              return (
                <div
                  key={`visitor-${i}`}
                  className={
                    classes.Visitor +
                    " " +
                    ((isConnected && classes.StatusConnected) ||
                      (isCheckedIn && classes.StatusCheckedIn) ||
                      classes.StatusInvited)
                  }
                >
                  <div className={classes.StatusContainer}>
                    <div className={classes.Status}>
                      <div
                        className={
                          classes.StatusColorCircle +
                          " " +
                          ((isConnected && classes.StatusConnected) ||
                            (isCheckedIn && classes.StatusCheckedIn) ||
                            classes.StatusInvited)
                        }
                      ></div>
                      {status}
                    </div>
                  </div>
                  <div className={classes.Picture}>
                    {takeawayFrame ? (
                      <div
                        className={classes.PictureTakeaway}
                        style={{ backgroundImage: `url('${takeawayFrame.url}')` }}
                      ></div>
                    ) : (
                      <div>
                        {(
                          visitorSession.visitor.fields.firstName.charAt(0) +
                          visitorSession.visitor.fields.lastName.charAt(0)
                        ).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className={classes.Name}>
                    {visitorSession.visitor.fields.firstName + " " + visitorSession.visitor.fields.lastName}
                  </div>
                  <div className={classes.Occupation}>{visitorSession.visitor.fields.jobTitle}</div>
                  <div className={classes.Company}>{visitorSession.visitor.fields.company}</div>
                </div>
              );
            })}
            <div className={classes.AddVisitorContainer}>
              <div
                className={classes.ButtonAddVisitor}
                onClick={() => window.open(process.env.REACT_APP_BACKSTAGE_URL + "/lobby/" + demoID, "_blank")}
              >
                <img src={addVisitorIcon} alt="Add visitor" />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.RightContent}>{sidebarPlaylist}</div>
      </>
    );
  }

  return <div className={classes.DemoContainer}>{content}</div>;
};

const mapStateToProps = (state) => {
  return {
    demos: state.airtableReducer.demos,
    sessions: state.airtableReducer.sessions,
    visitors: state.airtableReducer.visitors,
    currentDemo: state.mqttReducer.currentDemo,
    currentGuestsCheckedInIds: state.mqttReducer.currentGuestsCheckedInIds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeNavigationPage: (ids) => {
      return dispatch(actions.changeNavigationPage(ids));
    },
    getItemByID: (model, id) => {
      return dispatch(actions.getItemByID(model, id));
    },
    sendMQTTCurrentDemo: (demo) => {
      return dispatch(actions.sendMQTTCurrentDemo(demo));
    },
    sendMQTTCurrentProto: (proto) => {
      return dispatch(actions.sendMQTTCurrentProto(proto));
    },
    sendMQTTCurrentProject: (project) => {
      return dispatch(actions.sendMQTTCurrentProject(project));
    },
    sendMQTTCurrentPreset: (preset) => {
      return dispatch(actions.sendMQTTCurrentPreset(preset));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Demo);
