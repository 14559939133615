import React from "react";
import { Menu, Dropdown } from "antd";

import classes from "./Dropdown.module.sass";

import iconDropdownArrow from "shared/icons/dropdown_arrow.svg";

function DropdownMenu({ value, options, onChange, placeholder }) {
  const activeOption = options.find((option) => option.value === value);
  return (
    <Dropdown
      className={classes.Dropdown}
      overlay={
        <Menu className={classes.DropdownMenu}>
          <Menu.Item key="default" className={classes.DropdownMenuItem} onClick={() => onChange("")}>
            <div>-</div>
          </Menu.Item>
          {options.map((option, optionIndex) => {
            return (
              <Menu.Item key={optionIndex} className={classes.DropdownMenuItem} onClick={() => onChange(option.value)}>
                <div>{option.text}</div>
              </Menu.Item>
            );
          })}
        </Menu>
      }
      trigger={["click"]}
    >
      <div>
        {(activeOption && activeOption.text) || placeholder}
        <img src={iconDropdownArrow} alt="Dropdown arrow" />
      </div>
    </Dropdown>
  );
}

export default DropdownMenu;
