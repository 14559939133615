import React, { useContext, useEffect } from "react";

import classes from "./Login.module.sass";

import { GoogleAuthContext } from "../../utils/GoogleAuthProvider";

import svg_google from "shared/icons/google.svg";


function Login() {
  const { gapi } = useContext(GoogleAuthContext);

  useEffect(() => {
    if (!gapi) return;
    const buttonEl = document.getElementById("login-button");
    gapi.auth2.getAuthInstance().attachClickHandler(buttonEl, {});
  }, [gapi]);

  return (
    <div className={classes.Login}>
      <div className={classes.Content}>
        <div className={classes.Description}>You must be connected to access the Protocontrol</div>
        <div className={classes.Button} id="login-button">
          <img src={svg_google} className={classes.Icon} />
          Google Sign-In
        </div>
      </div>
    </div>
  );
}

export default Login;
