import * as actions from '../store/actions/index'
import { navigate } from '@reach/router'
import store from '../store-redux'

/**
 * This function is used when you want to update the breadcrumb and navigate to the desired page
 * Depending on the protoID, playlistID and demoID you specify the URL that you navigate to will be determined
 * After you navigated to the page, the current page ids corresponding to the page are stored in the Redux application reducer.
 *
 * @param ids object with protoID, playlistID and demoID of airtable (you can exclude playlistID and demoID)
 * @param dispatch deprecated
 */
export function navigateToPage(navigationData, dispatch) {
  const { path, demoID, playlistID, protoID, playlistItemID, projectID } = navigationData
  if(path) {
    navigate(path)
    navigationData = {demoID: null, playlistID: null, protoID: null}
  }
  else {
    if (demoID) {
      if(playlistItemID) {
        navigate(`/demos/${demoID}/${playlistID}/playlistItem/${playlistItemID}`)
      } else if (playlistID) {
        navigate(`/demos/${demoID}/${playlistID}`)
      } else {
        navigate(`/demos/${demoID}`)
      }
    } else if (playlistID) {
      if(playlistItemID) {
        navigate(`/playlists/${playlistID}/playlistItem/${playlistItemID}`)
      } else if (protoID) {
        navigate(`/protos/${protoID}`);
      } else {
        navigate(`/playlists/${playlistID}`)
      }
    } else if (protoID) {
      navigate(`/protos/${protoID}`);
    } else if (projectID) {
      navigate(`/projects/${projectID}`)
    }
  }
  store.dispatch(actions.changeNavigationPage(navigationData))
}

