import React from "react";

import classes from "./TagFilter.module.sass";

// Icons
import iconTagAugmentedGames from "shared/icons/tag_augmented_games.svg";
import iconTagAugmentedPerformance from "shared/icons/tag_augmented_performance.svg";
import iconTagInteractiveParcours from "shared/icons/tag_interactive_parcours.svg";
import iconTagInteractivePopup from "shared/icons/tag_interactive_popup.svg";
import iconTagLaw from "shared/icons/tag_law.svg";
import iconTagLivingVisual from "shared/icons/tag_living_visual.svg";

const filterIcons = {
  interactiveParcours: iconTagInteractiveParcours,
  augmentedGames: iconTagAugmentedGames,
  augmentedPerformances: iconTagAugmentedPerformance,
  livingVisual: iconTagLivingVisual,
  interactivePopup: iconTagInteractivePopup,
  interactiveParcours: iconTagInteractiveParcours,
  law: iconTagLaw,
};

function TagFilter({ tag, onClick, isActive }) {
  return (
    <div className={classes.FilterWrapper}>
      <div className={classes.Filter + " " + (isActive ? classes.FilterActive : "")} onClick={onClick}>
        <img src={filterIcons[tag.fields.uiIconKey]} className={classes.FilterIcon} />
        {tag.fields.name}
      </div>
    </div>
  );
}

export default TagFilter;
