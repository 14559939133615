import * as actionTypes from './actionTypes'
import axios from '../../axios-instance'

function fetchSessionsRequest() {
  return {
    type: actionTypes.FETCH_SESSIONS_REQUEST,
    receivedAt: Date.now()
  }
}

export function fetchSessionsSuccess(sessions) {
  return {
    type: actionTypes.FETCH_SESSIONS_SUCCESS,
    receivedAt: Date.now(),
    sessions
  }
}

function fetchSessionsFailed(error) {
  return {
    type: actionTypes.FETCH_SESSIONS_FAILED,
    receivedAt: Date.now(),
    error
  }
}

export function fetchSessions() {
  return function(dispatch) {
    dispatch(fetchSessionsRequest())
    return axios
      // .get('/Sessions?view=frontend_demos_detail')
      .get('/Sessions')
      .then(response => {
        return dispatch(fetchSessionsSuccess(response.data))
      })
      .catch(error => {
        return dispatch(fetchSessionsFailed(error))
      })
  }
}

function fetchDemosRequest() {
  return {
    type: actionTypes.FETCH_DEMOS_REQUEST,
    receivedAt: Date.now()
  }
}

export function fetchDemosSuccess(demos) {
  return {
    type: actionTypes.FETCH_DEMOS_SUCCESS,
    receivedAt: Date.now(),
    demos
  }
}

function fetchDemosFailed(error) {
  return {
    type: actionTypes.FETCH_DEMOS_FAILED,
    receivedAt: Date.now(),
    error
  }
}

export function fetchDemos() {
  return function(dispatch) {
    dispatch(fetchDemosRequest())
    return axios
      // .get('/Demos?view=frontend_demos_detail')
      .get('/Visits')
      .then(response => {
        return dispatch(fetchDemosSuccess(response.data))
      })
      .catch(error => {
        return dispatch(fetchDemosFailed(error))
      })
  }
}

function fetchProtosRequest() {
  return {
    type: actionTypes.FETCH_PROTOS_REQUEST,
    receivedAt: Date.now()
  }
}

export function fetchProtosSuccess(protos) {
  return {
    type: actionTypes.FETCH_PROTOS_SUCCESS,
    receivedAt: Date.now(),
    protos: protos.filter(proto => proto.fields.status !== "willNotFix")
  }
}

function fetchProtosFailed(error) {
  return {
    type: actionTypes.FETCH_PROTOS_FAILED,
    receivedAt: Date.now(),
    error
  }
}

export function fetchProtos() {
  return function(dispatch) {
    dispatch(fetchProtosRequest())
    return axios
      .get('/Protos')
      .then(response => {
        return dispatch(fetchProtosSuccess(response.data))
      })
      .catch(error => {
        return dispatch(fetchProtosFailed(error))
      })
  }
}

function fetchPlaylistsRequest() {
  return {
    type: actionTypes.FETCH_PLAYLISTS_REQUEST,
    receivedAt: Date.now()
  }
}

export function fetchPlaylistsSuccess(playlists) {
  return {
    type: actionTypes.FETCH_PLAYLISTS_SUCCESS,
    receivedAt: Date.now(),
    playlists
  }
}

function fetchPlaylistsFailed(error) {
  return {
    type: actionTypes.FETCH_PLAYLISTS_FAILED,
    receivedAt: Date.now(),
    error
  }
}

export function fetchPlaylists() {
  return function(dispatch) {
    dispatch(fetchPlaylistsRequest())
    return axios
      .get('/Playlists?view=playlist_list_view')
      .then(response => {
        return dispatch(fetchPlaylistsSuccess(response.data))
      })
      .catch(error => {
        return dispatch(fetchPlaylistsFailed(error))
      })
  }
}

function fetchPlaylistItemsRequest() {
  return {
    type: actionTypes.FETCH_PLAYLIST_ITEMS_REQUEST,
    receivedAt: Date.now()
  }
}

export function fetchPlaylistItemsSuccess(playlistItems) {
  return {
    type: actionTypes.FETCH_PLAYLIST_ITEMS_SUCCESS,
    receivedAt: Date.now(),
    playlistItems: playlistItems.filter(
      (playlistItem) =>
        playlistItem.fields.protoStatus?.[0] !== "willNotFix" && playlistItem.fields.projectStatus?.[0] !== "willNotFix"
    ),
  };
}

function fetchPlaylistItemsFailed(error) {
  return {
    type: actionTypes.FETCH_PLAYLIST_ITEMS_FAILED,
    receivedAt: Date.now(),
    error
  }
}

export function fetchPlaylistItems() {
  return function(dispatch) {
    dispatch(fetchPlaylistItemsRequest())
    return axios
      .get('/PlaylistItems')
      .then(response => {
        return dispatch(fetchPlaylistItemsSuccess(response.data))
      })
      .catch(error => {
        return dispatch(fetchPlaylistItemsFailed(error))
      })
  }
}

function fetchMediasRequest() {
  return {
    type: actionTypes.FETCH_MEDIAS_REQUEST,
    receivedAt: Date.now()
  }
}

export function fetchMediasSuccess(medias) {
  return {
    type: actionTypes.FETCH_MEDIAS_SUCCESS,
    receivedAt: Date.now(),
    medias
  }
}

function fetchMediasFailed(error) {
  return {
    type: actionTypes.FETCH_MEDIAS_FAILED,
    receivedAt: Date.now(),
    error
  }
}

export function fetchMedias() {
  return function(dispatch) {
    dispatch(fetchMediasRequest())
    return axios
      .get('/Medias')
      .then(response => {
        return dispatch(fetchMediasSuccess(response.data))
      })
      .catch(error => {
        return dispatch(fetchMediasFailed(error))
      })
  }
}

function fetchVisitorsRequest() {
  return {
    type: actionTypes.FETCH_VISITORS_REQUEST,
    receivedAt: Date.now()
  }
}

export function fetchVisitorsSuccess(visitors) {
  return {
    type: actionTypes.FETCH_VISITORS_SUCCESS,
    receivedAt: Date.now(),
    visitors
  }
}

function fetchVisitorsFailed(error) {
  return {
    type: actionTypes.FETCH_VISITORS_FAILED,
    receivedAt: Date.now(),
    error
  }
}

export function fetchVisitors() {
  return function(dispatch) {
    dispatch(fetchVisitorsRequest())
    return axios
      .get('/Visitors')
      .then(response => {
        return dispatch(fetchVisitorsSuccess(response.data))
      })
      .catch(error => {
        return dispatch(fetchVisitorsFailed(error))
      })
  }
}
function fetchDemonstratorsRequest() {
  return {
    type: actionTypes.FETCH_DEMONSTRATORS_REQUEST,
    receivedAt: Date.now()
  }
}

export function fetchDemonstratorsSuccess(demonstrators) {
  return {
    type: actionTypes.FETCH_DEMONSTRATORS_SUCCESS,
    receivedAt: Date.now(),
    demonstrators
  }
}

function fetchDemonstratorsFailed(error) {
  return {
    type: actionTypes.FETCH_DEMONSTRATORS_FAILED,
    receivedAt: Date.now(),
    error
  }
}

export function fetchDemonstrators() {
  return function(dispatch) {
    dispatch(fetchDemonstratorsRequest())
    return axios
      .get('/Demonstrators')
      .then(response => {
        return dispatch(fetchDemonstratorsSuccess(response.data))
      })
      .catch(error => {
        return dispatch(fetchDemonstratorsFailed(error))
      })
  }
}
function fetchResponsiblesRequest() {
  return {
    type: actionTypes.FETCH_RESPONSIBLES_REQUEST,
    receivedAt: Date.now()
  }
}

export function fetchResponsiblesSuccess(responsibles) {
  return {
    type: actionTypes.FETCH_RESPONSIBLES_SUCCESS,
    receivedAt: Date.now(),
    responsibles
  }
}

function fetchResponsiblesFailed(error) {
  return {
    type: actionTypes.FETCH_RESPONSIBLES_FAILED,
    receivedAt: Date.now(),
    error
  }
}

export function fetchResponsibles() {
  return function(dispatch) {
    dispatch(fetchResponsiblesRequest())
    return axios
      .get('/Responsibles')
      .then(response => {
        return dispatch(fetchResponsiblesSuccess(response.data))
      })
      .catch(error => {
        return dispatch(fetchResponsiblesFailed(error))
      })
  }
}
function fetchFeedbacksRequest() {
  return {
    type: actionTypes.FETCH_FEEDBACKS_REQUEST,
    receivedAt: Date.now()
  }
}

export function fetchFeedbacksSuccess(feedbacks) {
  return {
    type: actionTypes.FETCH_FEEDBACKS_SUCCESS,
    receivedAt: Date.now(),
    feedbacks
  }
}

function fetchFeedbacksFailed(error) {
  return {
    type: actionTypes.FETCH_FEEDBACKS_FAILED,
    receivedAt: Date.now(),
    error
  }
}

export function fetchFeedbacks() {
  return function(dispatch) {
    dispatch(fetchFeedbacksRequest())
    return axios
      .get('/Feedbacks')
      .then(response => {
        return dispatch(fetchFeedbacksSuccess(response.data))
      })
      .catch(error => {
        return dispatch(fetchFeedbacksFailed(error))
      })
  }
}

function fetchTechnologiesRequest() {
  return {
    type: actionTypes.FETCH_TECHNOLOGIES_REQUEST,
    receivedAt: Date.now()
  }
}

export function fetchTechnologiesSuccess(technologies) {
  return {
    type: actionTypes.FETCH_TECHNOLOGIES_SUCCESS,
    receivedAt: Date.now(),
    technologies
  }
}

function fetchTechnologiesFailed(error) {
  return {
    type: actionTypes.FETCH_TECHNOLOGIES_FAILED,
    receivedAt: Date.now(),
    error
  }
}

export function fetchTechnologies() {
  return function(dispatch) {
    dispatch(fetchTechnologiesRequest())
    return axios
      .get('/Technologies')
      .then(response => {
        return dispatch(fetchTechnologiesSuccess(response.data))
      })
      .catch(error => {
        return dispatch(fetchTechnologiesFailed(error))
      })
  }
}

function fetchTagsRequest() {
  return {
    type: actionTypes.FETCH_TAGS_REQUEST,
    receivedAt: Date.now()
  }
}

export function fetchTagsSuccess(tags) {
  return {
    type: actionTypes.FETCH_TAGS_SUCCESS,
    receivedAt: Date.now(),
    tags
  }
}

function fetchTagsFailed(error) {
  return {
    type: actionTypes.FETCH_TAGS_FAILED,
    receivedAt: Date.now(),
    error
  }
}

export function fetchTags() {
  return function(dispatch) {
    dispatch(fetchTagsRequest())
    return axios
      .get('/Tags')
      .then(response => {
        return dispatch(fetchTagsSuccess(response.data))
      })
      .catch(error => {
        return dispatch(fetchTagsFailed(error))
      })
  }
}

function fetchProjectsRequest() {
  return {
    type: actionTypes.FETCH_PROJECTS_REQUEST,
    receivedAt: Date.now()
  }
}

export function fetchProjectsSuccess(projects) {
  return {
    type: actionTypes.FETCH_PROJECTS_SUCCESS,
    receivedAt: Date.now(),
    projects
  }
}

function fetchProjectsFailed(error) {
  return {
    type: actionTypes.FETCH_PROJECTS_FAILED,
    receivedAt: Date.now(),
    error
  }
}

export function fetchProjects() {
  return function(dispatch) {
    dispatch(fetchProjectsRequest())
    return axios
      .get('/mfprojects')
      .then(response => {
        return dispatch(fetchProjectsSuccess(response.data))
      })
      .catch(error => {
        return dispatch(fetchProjectsFailed(error))
      })
  }
}

export function getItemByID(model, id) {
  return function(dispatch, getState) {
    const airtableState = getState().airtableReducer
    if(model in airtableState) {
      return airtableState[model].find(i => i.id === id)
    }
    return null
  }
}
